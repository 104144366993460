import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
  Button,
  Modal,
  ListGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import signature from "~/assets/img/icons/signature.svg";
import pdfsample from "../utils/pdfsample";
import Spinner from "~/components/Spinner";
import { getSignedPdfs } from "~/store/ducks/onboarding/actionTypes";
import {
  getIflowDocument,
  substituteIflowDocument,
  setSelectedTosign,
  postIflowFormData,
} from "~/store/ducks/processes/actionTypes";
import { withNamespaces } from "react-i18next";
import oauth from "~/utils/oauth";
import { setSignedPdf } from "~/store/ducks/onboarding/actionTypes";
import SignPdfModal from "./SignPdfModal";
import FileInput from "~/components/FileInput";
import { FaFileSignature, FaTrash } from "react-icons/fa";
import { SIGNATURE_COORDINATES_X, SIGNATURE_COORDINATES_Y } from "../utils";
import MultipleFileInputTypes from "./MultipleFileInputTypes";
import { transformFormData } from "../../CreateForm/utils";

const devLogConsole = require("~/utils/devLog");

const CreateMultipleFileInput = (props) => {
  const {
    t,
    field,
    signRef,
    isTableChild,
    isChild,
    styleSheet,
    translations,
    language,
  } = props;
  const { iflowDocument, isLoadingIflowDocument, selectedToSign } = useSelector(
    (state) => state.processesReducer
  );
  const { signedPdf, isLoadingGetSignedPdf } = useSelector(
    (state) => state.onboardingReducer
  );
  const { user } = useSelector((selectorState) => selectorState.globalReducer);

  const { isLoadingUserStamp } = useSelector(
    (selectorState) => selectorState.processesSignatureReducer
  );

  const dispatch = useDispatch();

  const [required, setRequired] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isFormData, setIsFormData] = useState(true);
  const [showSignModal, setShowSignModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [documentBlobs, setDocumentBlobs] = useState([]);
  const [uploadLimit, setUploadLimit] = useState(1);
  const [base64, setBase64] = useState("");
  const [signedIds, setSignedIds] = useState([]);
  const [selectedCheck, setSelectedCheck] = useState([]);
  const [updateCoordinatesObj, setUpdateCoordinatesObj] = useState(null);
  const [newSatetFile, setNewSatetFile] = useState([]);
  const [state, setState] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  //set initial states

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (field) {
      if (field.file) {
        if (field.file.link_url !== "") setIsFormData(false);
        if (field.file.asSignatures === "true") {
          setSignedIds((signedIds) => [...signedIds, { docid: field.file.id }]);
        }
        if (Array.isArray(field.file)) {
          let arr = [];
          field.file.forEach((i) => {
            if (i.asSignatures === "true") {
              arr.push({ docid: i.id });
            }
          });
          setSignedIds(arr);
        }
      }
      if (field.upload_limit != "" && parseInt(field.upload_limit)) {
        setUploadLimit(parseInt(field.upload_limit));
      }
      if (field.obligatory) {
        if (field.obligatory === "true") setRequired(true);
        else setRequired(false);
      }
      if (field.upload_enabled === "false") {
        setIsFormData(false);
      }

      //get signed from Ref
      if (signRef?.current !== undefined && signRef.current.length > 0) {
        setSignedIds((signedIds) => [...signedIds, ...signRef.current]);
      }
    }
  }, [field]);

  //start sign process
  useEffect(() => {
    if (Array.isArray(field.file)) {
      if (
        Array.isArray(iflowDocument) &&
        Array.isArray(selectedToSign) &&
        iflowDocument.length > 0 &&
        selectedToSign.length > 0
      ) {
        let filesReturned = true;
        selectedToSign.forEach((check) => {
          if (!iflowDocument.some((i) => i.docid === check?.file?.id)) {
            //not all files have returned from iflow yet
            filesReturned = false;
          }
        });
        if (filesReturned) {
          let isSigningField = false;
          //check if iflowDocument has field docids
          field.file.forEach((file) => {
            if (iflowDocument.some((d) => d.docid === file.id)) {
              isSigningField = true;
            }
          });
          if (isSigningField) {
            let blobsarr = [];
            if (selectedToSign.length > 1) {
              iflowDocument.forEach((d) => {
                //convert file to Blob
                const file = new Blob([d.file], {
                  type: "application/pdf",
                });
                blobsarr.push({
                  file: file,
                  filename: d.filename,
                  docid: d.docid,
                });
              });
              setDocumentBlobs(blobsarr);

              setBase64(pdfsample);

              handleShowSignModal();
            } else {
              iflowDocument.forEach((d) => {
                //convert file to Blob
                const file = new Blob([d.file], {
                  type: "application/pdf",
                });
                if (d.docid === selectedToSign[0]?.file?.id) {
                  fileToBase64(file)
                    .then((result) => {
                      setBase64(result);
                    })
                    .catch((e) => Error(e));
                }

                blobsarr.push({
                  file: file,
                  filename: d.filename,
                  docid: d.docid,
                });
              });
              setDocumentBlobs(blobsarr);

              handleShowSignModal();
            }
          }
          //else not signing this field
        }
      }
    } else {
      //single file
      //convert file to Blob
      if (Array.isArray(iflowDocument) && iflowDocument.length > 0 && field) {
        //check if iflowDocument has field docid
        if (iflowDocument.some((i) => i.docid === field?.file?.id)) {
          //get file by docid
          const foundItem = iflowDocument.find(
            (i) => i.docid === field?.file?.id
          );
          const file = new Blob([foundItem.file], {
            type: "application/pdf",
          });

          fileToBase64(file)
            .then((result) => {
              setBase64(result);
            })
            .catch((e) => Error(e));

          setDocumentBlobs([
            {
              file: file,
              filename: foundItem.filename.replaceAll("/", "symbolBarra"),
              docid: foundItem.docid,
            },
          ]);
          if (selectedToSign.length > 0) {
            handleShowSignModal();
          }
        }
      }
    }
  }, [iflowDocument, field, selectedToSign, styleSheet]);

  const isHash = (name) => {
    if (field.file) {
      let arr = field.file;
      if (!Array.isArray(field.file)) {
        arr = [field.file];
      }
      let isUpdatingIflowDoc = false;
      arr.forEach((i) => {
        if (selectedToSign.some((item) => item?.file?.id === i.id)) {
          isUpdatingIflowDoc = true;
        }
      });
      if (isUpdatingIflowDoc) {
        const id = arr.find(
          (i) =>
            i.name.toLocaleLowerCase() === `${name}.pdf`.toLocaleLowerCase()
        )?.id;

        if (id && id.includes("hdoc=")) {
          const arr = id.split("hdoc=");
          return true;
        } else return false;
      }
    }
    return false;
  };

  //Substitute the document in IFlow with the signed one
  useEffect(() => {
    const addItem = (id, array) => {
      if (!array.some((i) => i.docid === id)) {
        array.push({ docid: id });
      }
    };
    if (signedPdf) {
      if (signedPdf.fileList && signedPdf.fileList.length > 0) {
        signedPdf.fileList.forEach((item) => {
          const base64String = item.file;
          if (base64String) {
            //convert base64 to file
            const url = `data:application/pdf;base64,${base64String}`;
            function urltoFile(url, filename, mimeType) {
              return fetch(url)
                .then(function (res) {
                  return res.arrayBuffer();
                })
                .then(function (buf) {
                  return new File([buf], filename, { type: mimeType });
                });
            }

            const findDocId = (name) => {
              if (field.file) {
                let arr = field.file;
                if (!Array.isArray(field.file)) {
                  arr = [field.file];
                }
                let isUpdatingIflowDoc = false;
                arr.forEach((i) => {
                  if (selectedToSign.some((item) => item?.file?.id === i.id)) {
                    isUpdatingIflowDoc = true;
                  }
                });
                if (isUpdatingIflowDoc) {
                  const id = arr.find(
                    (i) =>
                      i.name.toLocaleLowerCase() ===
                      `${name}.pdf`.toLocaleLowerCase()
                  )?.id;

                  if (id && id.includes("hdoc=")) {
                    const arr = id.split("hdoc=");
                    devLogConsole("HDOC:", arr[1]);
                    return arr[1];
                  } else return id;
                }
              }
              return null;
            };

            const itemName = item.name.replaceAll("symbolBarra", "/");
            urltoFile(url, itemName, "application/pdf").then(function (file) {
              const data = new FormData();
              const allFormdata = new FormData(
                document.getElementsByName("dados")[0]
              );
              const flowId = isTableChild
                ? field.flowid
                : allFormdata.get("flowid");
              const pid = isTableChild ? field.pid : allFormdata.get("pid");
              const subpid = isTableChild
                ? field.subpid
                : allFormdata.get("subpid");

              //from AppletDocParameters.java

              data.append("file", file);
              data.append("variable", `${field.variable}`);

              data.append("update", "true");
              data.append("NUMASS", "1");

              if (isHash(itemName)) {
                data.append("hdoc", findDocId(itemName));
              } else {
                data.append("docid", findDocId(itemName));
              }

              data.append("flowid", flowId);
              data.append("pid", pid);
              data.append("subpid", 1);
              const url_ =
                "/DocumentService?" + "Authorization=" + oauth.getAccessToken();

              if (
                findDocId(itemName) !== null &&
                findDocId(itemName) !== undefined
              ) {
                //to do subpid generelized
                const updateCoords = {
                  ...updateCoordinatesObj,
                  docid: findDocId(itemName),
                  flowId: flowId,
                  pid: pid,
                  subpid: 1,
                  isHash: isHash(itemName),
                };
                dispatch(substituteIflowDocument(url_, data, updateCoords));
              }
            });

            addItem(findDocId(item.name), signRef.current);
          }
        });
        dispatch(setSignedPdf({}));
        setSelectedCheck([]);
        dispatch(setSelectedTosign([]));
      }
    }
  }, [signedPdf, field, selectedToSign]);

  const handleOnChoose = (event) => {
    if (styleSheet?.inputFileShowFileList) {
      if (event?.length <= uploadLimit) {
        const selectedFiles = [];

        for (let i = 0; i < event.length; i++) {
          selectedFiles.push(event[i]);
        }

        setSelectedFiles(selectedFiles);
      } else {
        alert(`You can only upload a maximum of ${uploadLimit} files`);
      }
    } else {
      if (event?.target?.files?.length <= uploadLimit) {
        const selectedFiles = [];
        for (let i = 0; i < event.target.files.length; i++) {
          selectedFiles.push(event.target.files[i]);
        }
        setSelectedFiles(selectedFiles);
      } else {
        alert(`You can only upload a maximum of ${uploadLimit} files`);
      }
    }
  };

  useEffect(() => {
    // if (selectedFiles.length > 0 && styleSheet.loadFileSignature) {
    if (selectedFiles.length > 0) {
      const fileMetadata = selectedFiles.map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        webkitRelativePath: file.webkitRelativePath,
      }));

      localStorage.setItem(
        "selectedFilesMetadata",
        JSON.stringify(fileMetadata)
      );
    }

    const storedFilesMetadata = JSON.parse(
      localStorage.getItem("selectedFilesMetadata")
    );

    if (storedFilesMetadata != null) {
      const dataTransfer = new DataTransfer();
      const blob = new Blob([JSON.stringify(storedFilesMetadata[0])], {
        type: storedFilesMetadata[0].type,
      });
      const reader = new FileReader();

      reader.onload = function () {
        const newArray = new Uint8Array(storedFilesMetadata[0].size);
        newArray.set(new Uint8Array(reader.result));
        const newBlob = new Blob([newArray], {
          type: storedFilesMetadata[0].type,
        });
        const newFile = new File([newBlob], storedFilesMetadata[0].name, {
          type: storedFilesMetadata[0].type,
          lastModified: storedFilesMetadata[0].lastModified,
          lastModifiedDate: storedFilesMetadata[0].lastModifiedDate,
        });
        dataTransfer.items.add(newFile);
        setNewSatetFile([newFile]);
      };
      reader.readAsArrayBuffer(blob);
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (newSatetFile.length > 0 && selectedFiles.length === 0) {
      handleOnChoose(newSatetFile);
      setState(newSatetFile);
      setNewSatetFile([]);
      localStorage.removeItem("selectedFilesMetadata");
    }
  }, [newSatetFile]);

  const handleShowSignModal = () => {
    setShowSignModal(true);
  };

  const handleCloseSignModal = () => {
    setShowSignModal(false);
  };

  const handleFileRemove = () => {
    setSelectedFiles([]);
    const fileInput = document.getElementById(`file-${field.variable}`);
    if (fileInput) fileInput.value = null;
  };

  //get iflow document to sign it
  const handleSignClick = (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    if (file && file.link_url !== "") {
      const payload = {
        url: file.link_url + "&Authorization=" + oauth.getAccessToken(),
        docid: file.id,
        filename: file.name,
      };
      dispatch(getIflowDocument(payload));
      dispatch(
        setSelectedTosign([
          {
            file: file,
            flowid: field.flowid,
            pid: field.pid,
            subpid: field.subpid,
          },
        ])
      );
    }
  };

  //sign document
  async function testSignPdf(
    coordinates,
    pageNumber,
    lastPage,
    reduced,
    givenLocation,
    givenReason,
    rectCoord,
    rectCoordTopLeft,
    rectCoordBottomRight,
    longText,
    stampAll,
    stampImage,
    stampWithoutSign,
    isCustomLogo = false
  ) {
    let payload = [];
    //convert blobs to base64 strings
    devLogConsole("selectedToSign and blobs", selectedToSign, documentBlobs);
    const promises = documentBlobs.map(async (d) => {
      const result = await fileToBase64(d?.file).catch((e) => Error(e));
      return {
        file: result,
        filename: d.filename,
        id: d.docid,
        stampImage: stampImage,
      };
    });
    const results = await Promise.all(promises);
    if (results) {
      results.forEach((r) => {
        try {
          const part =
            r.file && r.file.split("data:application/pdf;base64,")[1];
          //const stampPart = r.stampImage && r.stampImage.split(",")[1];
          const stampPart = r.stampImage;
          if (part) {
            if (
              Array.isArray(selectedToSign) &&
              selectedToSign.some((i) => i?.file?.id === r.id)
            )
              payload.push({
                file: part,
                fileName: r?.filename.replace(/\.[^/.]+$/, ""), //without extension
                stampImage: stampPart,
              });
          }
        } catch (e) {
          devLogConsole("error", e);
          devLogConsole("result", r);
        }
      });
    }

    if (payload.length > 0) {
      const queryParams = {
        posX: coordinates.x ? coordinates.x : SIGNATURE_COORDINATES_X,
        posY: coordinates.y ? coordinates.y : SIGNATURE_COORDINATES_Y,
        pagina: pageNumber ? pageNumber : 1,
        ultima: lastPage !== null ? lastPage : false,
        visivel: stampImage && styleSheet?.signatureOrStamp ? false : true,
        reduzido: reduced !== null ? reduced : true,
        givenLocation,
        givenReason,
        additionalText: longText,
        boxLowerLeftX: rectCoord ? rectCoord.x : 0.1,
        boxLowerLeftY: rectCoord ? rectCoord.y : 0.1,
        boxHigerLeftX: rectCoordTopLeft ? rectCoordTopLeft.x : 0.1,
        boxHigherLeftY: rectCoordTopLeft ? rectCoordTopLeft.y : 0.1,
        boxLowerRightX: rectCoordBottomRight ? rectCoordBottomRight.x : 0.1,
        boxLowerRightY: rectCoordBottomRight ? rectCoordBottomRight.y : 0.1,
        stampAll: stampAll,
        stampWithoutSign: stampWithoutSign,
        isCustomLogo: isCustomLogo,
      };

      if (payload.length == 1) {
        setUpdateCoordinatesObj({
          x: coordinates.x ? coordinates.x : SIGNATURE_COORDINATES_X,
          y: coordinates.y ? coordinates.y : SIGNATURE_COORDINATES_Y,
          numpages: pageNumber ? pageNumber : 1,
        });
      }

      dispatch(getSignedPdfs(payload, queryParams));
    }
  }

  const handleSignAll = (e) => {
    e.stopPropagation();
    e.preventDefault();
    selectedCheck.forEach((item) => {
      if (Array.isArray(field.file)) {
        const foundItem = field.file.find((i) => i.id === item?.file?.id);
        if (foundItem) {
          if (foundItem && foundItem.link_url !== "") {
            const payload = {
              url:
                foundItem.link_url + "&Authorization=" + oauth.getAccessToken(),
              docid: foundItem.id,
              filename: foundItem.name,
            };

            dispatch(getIflowDocument(payload));
          }
        }
      }
    });

    if (selectedCheck.length > 0) dispatch(setSelectedTosign(selectedCheck));
  };

  const handleRemoveAll = () => {
    if (selectedCheck.length > 0) {
      setShowRemoveModal(true);
    }
  };
  const handleCloseRemoveModal = () => {
    setShowRemoveModal(false);
  };

  const confirmRemoveAll = (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    selectedCheck.forEach((item) => {
      const formdata = transformFormData(
        new FormData(document.getElementsByName("dados")[0])
      );
  
      const foundItem = Array.isArray(field.file)
        ? field.file.find((i) => i.id === item?.file?.id)
        : field?.file;
  
      if (foundItem && foundItem.link_url !== "") {
        formdata.append(`anexos_rem_${foundItem.id}`, true);
  
        const url =
          "/Form/form.jsp?" +
          "flowid=" +
          formdata.get("flowid") +
          "&pid=" +
          formdata.get("pid") +
          "&subpid=" +
          formdata.get("subpid") +
          "&Authorization=" +
          oauth.getAccessToken();
        dispatch(postIflowFormData(formdata, url));
      }
    });
    
    setShowRemoveModal(false);
  };

  const handleCheck = (e, file) => {
    if (!selectedCheck.some((i) => i?.file?.id === file.id)) {
      const item = {
        file: file,
        flowid: field.flowid,
        pid: field.pid,
        subpid: field.subpid,
      };
      if (e.target.checked) {
        setSelectedCheck((selectedCheck) => [...selectedCheck, item]);
      }
    } else {
      if (!e.target.checked) {
        setSelectedCheck((selectedCheck) =>
          selectedCheck.filter((i) => i?.file?.id != file.id)
        );
      }
    }
  };

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const renderFileList = () => {
    let array = field.file;
    if (!Array.isArray(field.file)) {
      array = [field.file];
    }
    const renderItems = () => {
      return array.map((f, index) => {
        const extension = f?.name?.split(".").pop().toLocaleLowerCase();

        return (
          <div
            style={{
              display: "flex",
              justifyContent:
                array.length > 1 ||
                isTableChild ||
                field?.show_remove === "true"
                  ? "space-between"
                  : "start",
              flexWrap: "nowrap",
            }}
            key={index}
          >
            {f.link_url && (
              <a
                download={f.link_text ? f.link_text : "Sample.pdf"}
                href={f.link_url + "&Authorization=" + oauth.getAccessToken()}
              >
                {f.link_text ? f.link_text : "File"}
              </a>
            )}

            {f?.tosign === "true" &&
              extension === "pdf" &&
              field?.signatureType &&
              (field?.show_remove === "true" ||
                field?.signatureType !== "NONE") && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isTableChild ? "start" : "center",
                    marginLeft: isTableChild ? "0" : "15px",
                  }}
                >
                  {(signedIds.some((i) => i.docid === f.id) ||
                    signRef.current.some((i) => i.docid === f.id)) && (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip`}>
                          {t("fileInput.signed")}
                        </Tooltip>
                      }
                    >
                      <i
                        className="icon-check"
                        style={{
                          margin: isTableChild ? "5px 10px" : "0 15px 0 0",
                          color: "#28a745",
                        }}
                      ></i>
                    </OverlayTrigger>
                  )}

                  {array.length <= 1 ? (
                    field?.show_remove === "true" &&
                    styleSheet?.showRemoveIcon ? (
                      <MultipleFileInputTypes
                        type="checkbox"
                        t={t}
                        f={f}
                        handleCheck={handleCheck}
                      />
                    ) : (
                      <MultipleFileInputTypes
                        type="icon"
                        t={t}
                        f={f}
                        handleSignClick={handleSignClick}
                        styleSheet={styleSheet}
                        signature={signature}
                      />
                    )
                  ) : (
                    <MultipleFileInputTypes
                      type="checkbox"
                      t={t}
                      f={f}
                      handleCheck={handleCheck}
                    />
                  )}
                </div>
              )}
          </div>
        );
      });
    };

    return (
      <div
        style={{
          width:
            field?.show_remove === "true"
              ? "500px"
              : array.length > 1
              ? "500px"
              : "auto",
          padding: "1px 0px",
        }}
      >
        {renderItems()}
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            gap: " 1.5rem",
          }}
        >
          {field?.signatureType &&
            (field?.show_remove === "true" ||
              field?.signatureType !== "NONE") && (
              <>
                {array.length > 1 && field?.signatureType !== "NONE" && (
                  <div style={{ float: "right", marginTop: "10px" }}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip">{t("fileInput.toSign")}</Tooltip>
                      }
                    >
                      {styleSheet.iconSignature === "FaFileSignature" ? (
                        <FaFileSignature
                          style={{
                            height: "20px",
                            width: "20px",
                            cursor: "pointer",
                            color: "#24579e",
                          }}
                          onClick={handleSignAll}
                        />
                      ) : (
                        <img
                          src={signature}
                          style={{
                            height: "30px",
                            width: "30px",
                            cursor: "pointer",
                          }}
                          onClick={handleSignAll}
                        />
                      )}
                    </OverlayTrigger>
                  </div>
                )}
                {field?.show_remove === "true" &&
                  styleSheet?.showRemoveIcon && (
                    <div style={{ float: "right", marginTop: "10px" }}>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip">
                            {t("fileInput.remove")}
                          </Tooltip>
                        }
                      >
                        <FaTrash
                          style={{
                            height: "18px",
                            width: "18px",
                            cursor: "pointer",
                            color: "#24579e",
                          }}
                          onClick={handleRemoveAll}
                        />
                      </OverlayTrigger>
                    </div>
                  )}
              </>
            )}
        </div>
      </div>
    );
  };

  const translateText = (textField) => {
    if (!textField.includes("::t::")) {
      return textField;
    }
    const lingua = language.trim();
    const cleanTextField = textField.replace(/'/g, "").trim();
    const splittext = cleanTextField.split("::t::");

    const textoFinal = splittext
      .map((tt) => {
        const splitSplitedText = tt.split("bmp.");
        if (splitSplitedText.length > 1 && translations?.[lingua]) {
          return (
            splitSplitedText[0] +
            translations[lingua]["bmp." + splitSplitedText[1]]
          );
        } else {
          return tt;
        }
      })
      .join("");

    return textoFinal;
  };

  //devLogConsole(selectedToSign, "selectedToSign");
  //devLogConsole(selectedCheck, "selectedCheck");
  return !isFormData && !field.file ? (
    <div></div>
  ) : (
    <>
      <Form.Group
        controlId={`formFile-${field.variable}`}
        as={Row}
        style={{
          flexWrap: "nowrap",
          marginLeft: "0px",
          marginRight: "0px",
          flexGrow: "1",
          display: isMobile ? "block" : "flex",
        }}
      >
        {!isTableChild && (
          <Form.Label column className="iflow-form-label-fix">
            {translateText(
              field.text && field.text.b ? field.text.b : field.text
            )}
          </Form.Label>
        )}
        <Col
          style={{
            padding: isTableChild ? "0px" : "0.375rem 0px",
            flexGrow: "2",
          }}
        >
          {isFormData ? (
            <div style={{ display: "flex" }}>
              <div>
                <FileInput
                  type="file"
                  id={`file-${field.variable}`}
                  style={{ width: "100%" }}
                  multiple={uploadLimit > 1 ? true : false}
                  name={
                    isFormData && `FilesToTransform?&variable=${field.variable}`
                  }
                  required={required}
                  onChange={(e) => handleOnChoose(e)}
                  styleSheet={styleSheet}
                  setState={setState}
                  state={state}
                  setNewSatetFile={setNewSatetFile}
                />

                <Form.Control.Feedback type="invalid">
                  {`O campo ${translateText(
                    field.text && field.text.b ? field.text.b : field.text
                  )} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </div>
              {!styleSheet?.inputFileShowFileList &&
                Array.isArray(selectedFiles) &&
                selectedFiles.length > 0 && (
                  <i
                    className="icon-remove mt-2"
                    onClick={() => handleFileRemove()}
                  />
                )}
            </div>
          ) : (
            renderFileList()
          )}
        </Col>
      </Form.Group>
      <SignPdfModal
        showSignModal={showSignModal}
        handleCloseSignModal={handleCloseSignModal}
        testSignPdf={testSignPdf}
        file={base64}
        isLoadingIflowDocument={isLoadingIflowDocument}
        styleSheet={styleSheet}
        username={user?.username}
        field={field}
        selectedToSign={selectedToSign}
      />
      <Modal
        show={showRemoveModal}
        onHide={() => handleCloseRemoveModal()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("fileInput.removeModalTile")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={styleSheet?.processScroll ? "scrollBar-visible" : ""}
        >
          <ListGroup>
            {selectedCheck.map((item, index) => (
              <ListGroup.Item key={index}>{item.file.name}</ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button className="card-button" onClick={(e) => confirmRemoveAll(e)}>
            {t("datacapture.general.confirm")}
          </Button>
          <Button
            className="card-button-red active"
            onClick={handleCloseRemoveModal}
          >
            {t("general.close")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Spinner
        spinning={
          isLoadingGetSignedPdf || isLoadingIflowDocument || isLoadingUserStamp
        }
        wrapper
      />
      {styleSheet?.highLightRows && !isChild && !isTableChild && <hr />}
    </>
  );
};
export default withNamespaces()(CreateMultipleFileInput);
