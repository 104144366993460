import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import InitalDataSection from "../components/InitalDataSection";
import ProjectDescriptionSection from "../components/ProjectDescriptionSection";
import "../assets/scss/_headerTitle.scss";
import Item from "../components/Item";
import oauth from "~/utils/oauth";
import { withNamespaces } from "react-i18next";
//import SimpleButton from "../components/SimpleButton";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import TabSeparator from "~/pages/Easypay/components/TabSeparator";
import TabSeparatorWrapper from "~/pages/Easypay/components/TabSeparatorWrapper";
import AddButton from "~/components/Buttons/AddButton";
import Proposal from "../components/Proposal";
import Modal from "~/pages/Easypay/components/Modal";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import uuid from "uuid/v1";
import BudgetDocumentPreviewPage from "../components/BudgetDocumentPreviewPage";
import BudgetDocumentPreviewPageParcial from "../components/BudgetDocumentPreviewPageParcial";
import BudgetDocumentPreviewPageRed from "../components/BudgetDocumentPreviewPageRed";
import {
  getIflowFormdata,
  setIflowTriggerLoading,
  setIflowFormdata,
} from "~/store/ducks/processes/actionTypes";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import { getBudgetmgrConfiguration } from "~/store/ducks/budgetmgrConfiguration/actionTypes";
import { setDocumentInfo } from "~/store/ducks/budgetManagement/actionTypes";
import Simulator from "../components/Simulator";
import Spinner from "~/pages/Easypay/components/Spinner";

import {
  setAllWorkplans,
  saveChangesWorkplan,
  setErrorUpdating,
  getWorkplanById,
  setWorkplanById,
  getAllWorkplansByOrgGroup,
} from "~/store/ducks/budgetManagement/actionTypes";

import { getDocumentByWorkplan } from "~/store/ducks/budgetManagement/actionTypes";
const devLogConsole = require("~/utils/devLog");
const WorkPlanPage = ({
  workplanIndex,
  defaultActive,
  proposalActivated,
  setShowWorkPlan,
  setFilters,
  t,
}) => {
  const popupTimer = useRef(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalReducer);
  const {
    workplans,
    errorUpdating,
    workplan,
    paymentTypes,
    departments,
    factoring,
    collaborators,
    expenses,
    projectTypes,
    providerTypes,
    expenditureTypes,
    workplanById,
  } = useSelector((state) => state.budgetManagementReducer);
  const { iflowTriggerLoading, formdata } = useSelector(
    (state) => state.processesReducer
  );

  const { budgetmgrConfiguration } = useSelector(
    (state) => state.budgetmgrConfigurationReducer
  );
  const [submittedWorkplans, setSubmittedWorkplans] = useState([]);
  const [isLockUser, setIsLocktoUser] = useState("");
  const [isLock, setIsLock] = useState(true);
  const [comeFromSave, setComeFromSave] = useState(false);

  const [letShowButtons, setLetShowButtons] = useState(false);

  const [didSubmit, setDidSubmit] = useState(false);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [isModalWaitingShowing, setIsModalWaitingShowing] = useState(false);
  const [isModalSubmitErrorShowing, setIsModalSubmitErrorShowing] =
    useState(false);

  const [errorString, setErrorString] = useState("");
  const [isModalShowingErrorFact, setIsModalShowingErrorFact] = useState(false);
  const [isModalSaveShowing, setIsModalSaveShowing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    isModalProjectNumberErrorShowing,
    setIsModalProjectNumberErrorShowing,
  ] = useState(false);
  const [isModalErrorUpdatingShowing, setIsModalErrorUpdatingShowing] =
    useState(false);
  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);
  const [proposalDisabled, setProposalDisabled] = useState(proposalActivated);
  const [showModalToGoBack, setShowModalToGoBack] = useState(false);
  const [calculateGoBackFunction, setCalculateGoBackFunction] = useState(false);
  const [workplanByIdCopy, setWorkplanByIdCopy] = useState({});

  var totalOpt1CostValue = 0;
  var totalOpt2CostValue = 0;
  var totalOpt3CostValue = 0;
  var totalOptSelectedCostValue = 0;
  const [startDate, setStartDate] = useState(new Date());

  var totalOpt1SaleValue = 0;
  var totalOpt2SaleValue = 0;
  var totalOpt3SaleValue = 0;
  var totalOptSelectedSaleValue = 0;

  const [option1CostValue, setOption1CostValue] = useState(0);
  const [option1SaleValue, setOption1SaleValue] = useState(0);
  const [option1FinalMargin, setOption1FinalMargin] = useState(0);

  const [option2CostValue, setOption2CostValue] = useState(0);
  const [option2SaleValue, setOption2SaleValue] = useState(0);
  const [option2FinalMargin, setOption2FinalMargin] = useState(0);

  const [option3CostValue, setOption3CostValue] = useState(0);
  const [option3SaleValue, setOption3SaleValue] = useState(0);
  const [option3FinalMargin, setOption3FinalMargin] = useState(0);

  const [optionSelectedCostValue, setOptionSelectedCostValue] = useState(0);
  const [optionSelectedSaleValue, setOptionSelectedSaleValue] = useState(0);
  const [optionSelectedFinalMargin, setOptionSelectedFinalMargin] = useState(0);

  const [showOption2, setShowOption2] = useState(false);
  const [showOption3, setShowOption3] = useState(false);

  const [monthsArray, setMonthsArray] = useState([]);
  const [factureArrayState, setFactureArrayState] = useState([]);
  const [costArrayState, setCostArrayState] = useState([]);
  const [tableTitle, setTableTitle] = useState({});
  const [totalFacture, setTotalFacture] = useState({});
  const [totalCost, setTotalCost] = useState({});
  const [income, setIncome] = useState({});
  const [percIncome, setPercIncome] = useState({});
  const [costHours, setCostHours] = useState({});
  const [hours, setHours] = useState({});
  const [margin, setMargin] = useState({});
  const [percMargin, setPercMargin] = useState({});
  const [marginOk, setMarginOk] = useState({});

  const [factureArrayStateVerify, setFactureArrayStateVerify] = useState([]);
  const [costArrayStateVerify, setCostArrayStateVerify] = useState([]);

  const [totalFactureVerify, setTotalFactureVerify] = useState({});
  const [totalCostVerify, setTotalCostVerify] = useState({});

  const [incomeVerify, setIncomeVerify] = useState({});
  const [percIncomeVerify, setPercIncomeVerify] = useState({});

  const [marginVerify, setMarginVerify] = useState({});
  const [percMarginVerify, setPercMarginVerify] = useState({});

  var factureArray = [];
  var factureArrayVerify = [];
  var costArray = [];
  var costArrayVerify = [];

  var costHoursElement = {};
  var hoursElement = {};

  var gpi = 1.0;
  var fse = 1.0;
  var gpd = 1.0;

  function getNameFromId() {
    const projType = projectTypes.find(
      (x) => x.idTipoProjeto === parseInt(workplans[workplanIndex].tipoProjeto)
    );
    if (projType != null) return projType?.nome;
  }

  function calculate() {
    if (expenses?.length != 0 && expenses != null && expenses != undefined) {
      const expGPI = expenses.find(
        (x) => x.tipo === "Gastos Pessoal Indireto (GPI)"
      );
      if (expGPI != null) gpi = expGPI.percentagem / 100;

      const expFSE = expenses.find((x) => x.tipo === "FSE");
      if (expFSE != null) fse = expFSE.percentagem / 100;

      const expGPD = expenses.find(
        (x) => x.tipo === "Gastos Pessoal Direto (GPD)"
      );
      if (expGPD != null) gpd = expGPD.percentagem / 100;
    } else {
      fse = 0;
      gpi = 0;
      gpd = 0;
    }

    var factureHoursElement = {};
    var factureSubElement = {};
    var factureCostElement = {};
    var facturFactElement = {};

    var factureHoursVerifyElement = {};
    var factureSubVerifyElement = {};
    var factureCostVerifyElement = {};
    var facturFactVerifyElement = {};

    var titleElement = {};
    var totalFactureElement = {};
    var totalCostElement = {};

    var totalFactureElementVerify = {};
    var totalCostElementVerify = {};

    var costSubElement = {};
    var costCostElement = {};
    var costFactElement = {};

    var costSubVerifyElement = {};
    var costCostVerifyElement = {};
    var costFactVerifyElement = {};

    var incomeElement = {};
    var percentIncomeElement = {};

    var marginElement = {};
    var initialMarginElement = {};
    var percentMarginElement = {};

    var incomeElementVerify = {};
    var percentIncomeElementVerify = {};
    var marginElementVerify = {};
    var initialMarginElementVerify = {};
    var percentMarginElementVerify = {};
    var marginOkElement = {};

    marginOkElement.description = "MARGEM OK/NOT";
    titleElement.description = "Descriçao";

    initialMarginElementVerify.description = "Margem";

    factureHoursVerifyElement.description = "Horas";
    factureSubVerifyElement.description = "Subcontratos";
    factureCostVerifyElement.description = "Despesas Admin";
    facturFactVerifyElement.description = "Factoring";

    factureHoursElement.description = "Horas";
    factureSubElement.description = "Subcontratos";
    factureCostElement.description = "Despesas Admin";
    facturFactElement.description = "Factoring";

    costSubElement.description = "Subcontratos";
    costCostElement.description = "Despesas Admin";
    costFactElement.description = "Factoring";

    costSubVerifyElement.description = "Subcontratos";
    costCostVerifyElement.description = "Despesas Admin";
    costFactVerifyElement.description = "Factoring";

    totalFactureElement.description = "Total a Faturar";
    totalCostElement.description = "Total Custos";

    totalFactureElementVerify.description = "Total a Faturar";
    totalCostElementVerify.description = "Total Custos";

    incomeElement.description = "Income";
    percentIncomeElement.description = "";

    costHoursElement.description = "Custo Horas";
    hoursElement.description = "Horas";

    marginElement.description = "Margem";
    initialMarginElement.description = "Margem";
    percentMarginElement.description = "";

    incomeElementVerify.description = "Income";
    percentIncomeElementVerify.description = "";
    marginElementVerify.description = "Margem";
    percentMarginElementVerify.description = "";

    for (var i = 0; i < departments.length; i++) {
      titleElement[departments[i].nome] = [departments[i].nome];

      marginOkElement[departments[i].nome] = 0;

      factureHoursElement[departments[i].nome] = 0;
      factureSubElement[departments[i].nome] = 0;
      factureCostElement[departments[i].nome] = 0;
      facturFactElement[departments[i].nome] = 0;

      initialMarginElementVerify[departments[i].nome] = 0;

      factureHoursVerifyElement[departments[i].nome] = 0;
      factureSubVerifyElement[departments[i].nome] = 0;
      factureCostVerifyElement[departments[i].nome] = 0;
      facturFactVerifyElement[departments[i].nome] = 0;

      initialMarginElement[departments[i].nome] = 0;

      costSubElement[departments[i].nome] = 0;
      costCostElement[departments[i].nome] = 0;
      costFactElement[departments[i].nome] = 0;

      costSubVerifyElement[departments[i].nome] = 0;
      costCostVerifyElement[departments[i].nome] = 0;
      costFactVerifyElement[departments[i].nome] = 0;

      totalFactureElement[departments[i].nome] = 0;
      totalCostElement[departments[i].nome] = 0;

      totalFactureElementVerify[departments[i].nome] = 0;
      totalCostElementVerify[departments[i].nome] = 0;

      incomeElement[departments[i].nome] = 0;
      percentIncomeElement[departments[i].nome] = 0;

      costHoursElement[departments[i].nome] = 0;
      hoursElement[departments[i].nome] = 0;

      marginElement[departments[i].nome] = 0;
      percentMarginElement[departments[i].nome] = 0;

      incomeElementVerify[departments[i].nome] = 0;
      percentIncomeElementVerify[departments[i].nome] = 0;

      marginElementVerify[departments[i].nome] = 0;
      percentMarginElementVerify[departments[i].nome] = 0;
    }

    titleElement.total = "Total";

    marginOkElement.total = 0;

    initialMarginElementVerify.total = 0;

    initialMarginElement.total = 0;

    costHoursElement.total = 0;

    hoursElement.total = 0;

    percentIncomeElement.total = 0;
    incomeElement.total = 0;
    percentMarginElement.total = 0;
    marginElement.total = 0;

    percentIncomeElementVerify.total = 0;
    incomeElementVerify.total = 0;
    percentMarginElementVerify.total = 0;
    marginElementVerify.total = 0;

    factureHoursElement.total = 0;
    factureSubElement.total = 0;
    factureCostElement.total = 0;
    facturFactElement.total = 0;

    factureHoursVerifyElement.total = 0;
    factureSubVerifyElement.total = 0;
    factureCostVerifyElement.total = 0;
    facturFactVerifyElement.total = 0;

    totalFactureElement.total = 0;

    totalFactureElementVerify.total = 0;

    costSubElement.total = 0;
    costCostElement.total = 0;
    costFactElement.total = 0;

    costSubVerifyElement.total = 0;
    costCostVerifyElement.total = 0;
    costFactVerifyElement.total = 0;

    totalCostElement.total = 0;

    totalCostElementVerify.total = 0;

    factureArray.push(factureHoursElement);
    factureArray.push(factureSubElement);
    factureArray.push(factureCostElement);
    factureArray.push(facturFactElement);

    factureArrayVerify.push(factureHoursVerifyElement);
    factureArrayVerify.push(factureSubVerifyElement);
    factureArrayVerify.push(factureCostVerifyElement);
    factureArrayVerify.push(facturFactVerifyElement);

    costArray.push(costSubElement);
    costArray.push(costCostElement);
    costArray.push(costFactElement);

    costArrayVerify.push(costSubVerifyElement);
    costArrayVerify.push(costCostVerifyElement);
    costArrayVerify.push(costFactVerifyElement);

    buildHoursToFacture();

    for (var i = 0; i < factureArray.length; i++) {
      Object.keys(factureArray[i]).map(
        (key, index) =>
          (totalFactureElement[key] =
            totalFactureElement[key] + factureArray[i][key])
      );
    }

    for (var i = 0; i < factureArrayVerify.length; i++) {
      Object.keys(factureArrayVerify[i]).map(
        (key, index) =>
          (totalFactureElementVerify[key] =
            totalFactureElementVerify[key] + factureArrayVerify[i][key])
      );
    }

    for (var i = 0; i < costArray.length; i++) {
      Object.keys(costArray[i]).map(
        (key, index) =>
          (totalCostElement[key] = totalCostElement[key] + costArray[i][key])
      );
    }

    for (var i = 0; i < costArrayVerify.length; i++) {
      Object.keys(costArrayVerify[i]).map(
        (key, index) =>
          (totalCostElementVerify[key] =
            totalCostElementVerify[key] + costArrayVerify[i][key])
      );
    }

    Object.keys(totalFactureElement).map(
      (key, index) =>
        (incomeElement[key] =
          parseFloat(totalFactureElement[key]) -
          parseFloat(totalCostElement[key]))
    );

    Object.keys(costHoursElement).map((key, index) => {
      if (key != "total" && key != "description") {
        costHoursElement.total = costHoursElement.total + costHoursElement[key];
      }
    });

    Object.keys(hoursElement).map((key, index) => {
      if (key != "total" && key != "description") {
        const hoursStringArray = hoursElement[key].toString().split(".");
        var minutes = "0." + hoursStringArray[1];
        minutes = Math.ceil(parseFloat(minutes) * 60);
        minutes = minutes.toString();
        if (minutes.length === 1) {
          minutes = "0" + minutes;
        }
        hoursElement[key] = hoursStringArray[0] + ":" + minutes;
      }
    });

    Object.keys(hoursElement).map((key, index) => {
      if (key != "total" && key != "description") {
        const hoursArray = hoursElement[key].split(":");
        var newHoras =
          parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;
        hoursElement.total = hoursElement.total + newHoras;
      }
    });

    const hoursStringArray = hoursElement.total.toString().split(".");

    var minutes = "0." + hoursStringArray[1];
    minutes = Math.ceil(parseFloat(minutes) * 60);
    minutes = minutes.toString();
    if (minutes.length === 1) {
      minutes = "0" + minutes;
    }
    hoursElement.total = hoursStringArray[0] + ":" + minutes;

    Object.keys(totalFactureElementVerify).map(
      (key, index) =>
        (incomeElementVerify[key] =
          parseFloat(totalFactureElementVerify[key]) -
          parseFloat(totalCostElementVerify[key]))
    );

    Object.keys(incomeElement).map(
      (key, index) =>
        (percentIncomeElement[key] =
          (parseFloat(incomeElement[key]) /
            parseFloat(totalFactureElement[key])) *
          100)
    );

    Object.keys(incomeElementVerify).map((key, index) => {
      if (totalFactureElementVerify[key] != 0) {
        percentIncomeElementVerify[key] =
          (incomeElementVerify[key] / totalFactureElementVerify[key]) * 100;
      }
    });

    var didCalculateMargin = false;
    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].selected
          ) {
            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                .length;
              m++
            ) {
              var currentHours =
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ];
              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentHours.departamentoHoras,
                    "departments"
                  ) === departments[a].nome
                ) {
                  didCalculateMargin = true;
                  const currentCollaborator = collaborators.find(
                    (x) =>
                      x.idColaborador === parseInt(currentHours.colaborador)
                  );

                  const hoursArray = currentHours.horasPropostas.split(":");
                  var newHoras =
                    parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;

                  if (getNameFromId() === "Avença") {
                    //newHoras =
                    //  newHoras *
                    //  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    //    .horas[m].horasPropostasArray.length;
                  }

                  var value1;
                  var value2;

                  if (
                    currentCollaborator != null &&
                    currentCollaborator != undefined
                  ) {
                    value1 = newHoras * currentCollaborator.precoCompra;
                    value2 = value1;
                    //factureArrayVerify[0][departments[a].nome] * fse +
                    //factureArrayVerify[0][departments[a].nome] * gpi;
                  }

                  initialMarginElementVerify[departments[a].nome] =
                    initialMarginElementVerify[departments[a].nome] + value2;

                  initialMarginElement[departments[a].nome] =
                    initialMarginElement[departments[a].nome] +
                    parseFloat(currentHours.custosHoras);
                }
              }
            }
          }
        }
      }
    }

    if (didCalculateMargin === false) {
      for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades.length;
          j++
        ) {
          for (
            var k = 0;
            k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                  .length;
                m++
              ) {
                var currentHours =
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .horas[m];
                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentHours.departamentoHoras,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    didCalculateMargin = true;
                    const currentCollaborator = collaborators.find(
                      (x) =>
                        x.idColaborador === parseInt(currentHours.colaborador)
                    );

                    const hoursArray = currentHours.horasPropostas.split(":");
                    var newHoras =
                      parseFloat(hoursArray[0]) +
                      parseFloat(hoursArray[1]) / 60;

                    if (getNameFromId() === "Avença") {
                      // newHoras =
                      //   newHoras *
                      //   workplans[workplanIndex].items[i].atividades[j].opcoes[
                      //     k
                      //   ].horas[m].horasPropostasArray.length;
                    }
                    var value1;
                    var value2;
                    if (
                      currentCollaborator != null &&
                      currentCollaborator != undefined
                    ) {
                      value1 = newHoras * currentCollaborator.precoCompra;
                      value2 = value1;
                      //factureArrayVerify[0][departments[a].nome] * fse +
                      // factureArrayVerify[0][departments[a].nome] * gpi;
                    }

                    initialMarginElementVerify[departments[a].nome] =
                      initialMarginElementVerify[departments[a].nome] + value2;

                    initialMarginElement[departments[a].nome] =
                      initialMarginElement[departments[a].nome] +
                      parseFloat(currentHours.custosHoras) * 1;
                  }
                }
              }
            }
          }
        }
      }
    }

    Object.keys(initialMarginElement).map((key, index) => {
      if (key != "total" && key != "description") {
        marginElement[key] =
          parseFloat(incomeElement[key]) -
          parseFloat(initialMarginElement[key]);

        marginElement.total +=
          parseFloat(incomeElement[key]) -
          parseFloat(initialMarginElement[key]);
      }
    });

    Object.keys(marginElement).map((key, index) => {
      if (
        initialMarginElementVerify[key] != 0 &&
        key != "total" &&
        key != "description"
      ) {
        initialMarginElementVerify[key] =
          initialMarginElementVerify[key] +
          factureArrayVerify[0][key] * fse +
          factureArrayVerify[0][key] * gpi;
        marginElementVerify[key] =
          parseFloat(incomeElementVerify[key]) -
          initialMarginElementVerify[key];

        marginElementVerify.total +=
          parseFloat(incomeElementVerify[key]) -
          parseFloat(initialMarginElementVerify[key]);
      }
    });

    Object.keys(marginElement).map(
      (key, index) =>
        (percentMarginElement[key] =
          (parseFloat(marginElement[key]) /
            parseFloat(totalFactureElement[key])) *
          100)
    );

    Object.keys(marginElementVerify).map(
      (key, index) =>
        (percentMarginElementVerify[key] =
          (marginElementVerify[key] / totalFactureElementVerify[key]) * 100)
    );

    Object.keys(percentMarginElementVerify).map((key, index) => {
      if (incomeElement[key] === 0) {
        marginOkElement[key] = -1;
      } else if (
        parseFloat(percentMarginElement[key].toFixed(2)) <
        parseFloat(percentMarginElementVerify[key].toFixed(2))
      ) {
        marginOkElement[key] = 0;
      } else {
        marginOkElement[key] = 1;
      }
    });

    workplans[workplanIndex].faturacao = totalFactureElement.total;
    workplans[workplanIndex].subcontratos = totalCostElement.total;
    workplans[workplanIndex].income = incomeElement.total;
    workplans[workplanIndex].percIncome = parseFloat(
      percentIncomeElement.total.toFixed(5)
    );
    // dispatch(
    //   saveChangesWorkplan({ content: workplans[workplanIndex], show: false })
    // );
  }

  function convertIdToName(id, name) {
    if (name === "departments") {
      const dep = departments.find((x) => x.idDepartamento === parseInt(id));
      if (dep != null) return dep?.nome;
    }
    if (name === "paymentTypes" && paymentTypes != null) {
      const payType = paymentTypes.find(
        (x) => x.idTipoPagamento === parseInt(id)
      );
      if (payType != null) return payType?.nome;
    }
  }

  function buildHoursToFacture() {
    var hasSelected = false;
    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].selected
          ) {
            hasSelected = true;

            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                .length;
              m++
            ) {
              var currentHours = global.structuredClone(
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ]
              );

              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentHours.departamentoHoras,
                    "departments"
                  ) === departments[a].nome
                ) {
                  factureArray[0].total =
                    factureArray[0].total +
                    parseFloat(currentHours.valorVendaHoras);
                  factureArray[0][departments[a].nome] =
                    factureArray[0][departments[a].nome] +
                    parseFloat(currentHours.valorVendaHoras);

                  const currentCollaborator = collaborators.find(
                    (x) =>
                      x.idColaborador === parseInt(currentHours.colaborador)
                  );

                  const hoursArray = currentHours.horasPropostas.split(":");

                  var newHoras =
                    parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;

                  hoursElement[departments[a].nome] = (
                    parseFloat(hoursElement[departments[a].nome]) + newHoras
                  ).toFixed(2);

                  if (
                    typeof currentHours.custosHoras === "string" ||
                    currentHours.custosHoras instanceof String
                  ) {
                    costHoursElement[departments[a].nome] =
                      costHoursElement[departments[a].nome] +
                      parseFloat(currentHours.custosHoras);
                  } else {
                    costHoursElement[departments[a].nome] =
                      costHoursElement[departments[a].nome] +
                      currentHours.custosHoras;
                  }
                  if (
                    typeof costHoursElement[departments[a].nome] === "string" ||
                    costHoursElement[departments[a].nome] instanceof String
                  ) {
                    costHoursElement[departments[a].nome] = parseFloat(
                      costHoursElement[departments[a].nome]
                    );
                  }

                  if (getNameFromId() === "Avença") {
                    // newHoras =
                    //   newHoras *
                    //   workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    //     .horas[m].horasPropostasArray.length;
                  }
                  var value1;
                  var value2;

                  if (
                    currentCollaborator != null &&
                    currentCollaborator != undefined
                  ) {
                    value1 = newHoras * currentCollaborator.precoCompra;
                    value2 = value1 / gpd;
                  }

                  if (value2 > parseFloat(currentHours.valorVendaHoras)) {
                    factureArrayVerify[0][departments[a].nome] =
                      factureArrayVerify[0][departments[a].nome] +
                      parseFloat(currentHours.valorVendaHoras);
                    factureArrayVerify[0].total =
                      factureArrayVerify[0].total +
                      parseFloat(currentHours.valorVendaHoras);
                  } else {
                    factureArrayVerify[0][departments[a].nome] =
                      factureArrayVerify[0][departments[a].nome] +
                      parseFloat(value2);
                    factureArrayVerify[0].total =
                      factureArrayVerify[0].total + parseFloat(value2);
                  }
                }
              }
            }

            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                .fornecedores.length;
              m++
            ) {
              var currentSuppliers = global.structuredClone(
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .fornecedores[m]
              );
              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentSuppliers.departamentoForn,
                    "departments"
                  ) === departments[a].nome
                ) {
                  factureArray[1].total =
                    factureArray[1].total +
                    parseFloat(currentSuppliers.valorVendaForn);

                  factureArray[1][departments[a].nome] =
                    factureArray[1][departments[a].nome] +
                    parseFloat(currentSuppliers.valorVendaForn);

                  costArray[0].total =
                    costArray[0].total +
                    parseFloat(currentSuppliers.precoCompraForn);

                  costArray[0][departments[a].nome] =
                    costArray[0][departments[a].nome] +
                    parseFloat(currentSuppliers.precoCompraForn);

                  if (
                    currentSuppliers.precoCompraForn != 0 &&
                    currentSuppliers.precoCompraForn != null &&
                    currentSuppliers.precoCompraForn != undefined
                  ) {
                    const currentSupplier = providerTypes.find(
                      (x) =>
                        x.idTipoFornecedor === currentSuppliers.tipoFornecedor
                    );

                    factureArrayVerify[1].total =
                      factureArrayVerify[1].total +
                      parseFloat(
                        currentSuppliers.precoCompraForn /
                          (1 - currentSupplier.percentagem / 100)
                      );

                    factureArrayVerify[1][departments[a].nome] =
                      factureArrayVerify[1][departments[a].nome] +
                      parseFloat(
                        currentSuppliers.precoCompraForn /
                          (1 - currentSupplier.percentagem / 100)
                      );
                    costArrayVerify[0].total =
                      costArrayVerify[0].total +
                      parseFloat(currentSuppliers.precoCompraForn);

                    costArrayVerify[0][departments[a].nome] =
                      costArrayVerify[0][departments[a].nome] +
                      parseFloat(currentSuppliers.precoCompraForn);
                  }
                }
              }
            }
            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].despesas
                .length;
              m++
            ) {
              var currentCosts = global.structuredClone(
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .despesas[m]
              );
              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentCosts.departamentoDesp,
                    "departments"
                  ) === departments[a].nome
                ) {
                  factureArray[2].total =
                    factureArray[2].total +
                    parseFloat(currentCosts.valorVendaDesp);

                  factureArray[2][departments[a].nome] =
                    factureArray[2][departments[a].nome] +
                    parseFloat(currentCosts.valorVendaDesp);

                  costArray[1].total =
                    costArray[1].total +
                    parseFloat(currentCosts.precoCompraForn);

                  costArray[1][departments[a].nome] =
                    costArray[1][departments[a].nome] +
                    parseFloat(currentCosts.precoCompraForn);

                  if (
                    currentCosts.precoCompraForn != 0 &&
                    currentCosts.precoCompraForn != null &&
                    currentCosts.precoCompraForn != undefined
                  ) {
                    const currentExpense = expenditureTypes.find(
                      (x) =>
                        x.idTipoDespesa === parseInt(currentCosts.tipoDespesa)
                    );
                    factureArrayVerify[2].total =
                      factureArrayVerify[2].total +
                      parseFloat(
                        currentCosts.precoCompraForn /
                          (1 - currentExpense.percentagem / 100)
                      );

                    factureArrayVerify[2][departments[a].nome] =
                      factureArrayVerify[2][departments[a].nome] +
                      parseFloat(
                        currentCosts.precoCompraForn /
                          (1 - currentExpense.percentagem / 100)
                      );

                    costArrayVerify[1].total =
                      costArrayVerify[1].total +
                      parseFloat(currentCosts.precoCompraForn);

                    costArrayVerify[1][departments[a].nome] =
                      costArrayVerify[1][departments[a].nome] +
                      parseFloat(currentCosts.precoCompraForn);
                  }
                }
              }
            }
          }
        }
      }
    }

    if (!hasSelected) {
      for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades.length;
          j++
        ) {
          for (
            var k = 0;
            k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                  .length;
                m++
              ) {
                var currentHours = global.structuredClone(
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .horas[m]
                );

                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentHours.departamentoHoras,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    factureArray[0].total =
                      factureArray[0].total +
                      parseFloat(currentHours.valorVendaHoras);
                    factureArray[0][departments[a].nome] =
                      factureArray[0][departments[a].nome] +
                      parseFloat(currentHours.valorVendaHoras);

                    const currentCollaborator = collaborators.find(
                      (x) =>
                        x.idColaborador === parseInt(currentHours.colaborador)
                    );

                    const hoursArray = currentHours.horasPropostas.split(":");

                    var newHoras =
                      parseFloat(hoursArray[0]) +
                      parseFloat(hoursArray[1]) / 60;

                    hoursElement[departments[a].nome] = (
                      parseFloat(hoursElement[departments[a].nome]) + newHoras
                    ).toFixed(2);

                    if (
                      typeof currentHours.custosHoras === "string" ||
                      currentHours.custosHoras instanceof String
                    ) {
                      costHoursElement[departments[a].nome] =
                        costHoursElement[departments[a].nome] +
                        parseFloat(currentHours.custosHoras);
                    } else {
                      costHoursElement[departments[a].nome] =
                        costHoursElement[departments[a].nome] +
                        currentHours.custosHoras;
                    }
                    if (
                      typeof costHoursElement[departments[a].nome] ===
                        "string" ||
                      costHoursElement[departments[a].nome] instanceof String
                    ) {
                      costHoursElement[departments[a].nome] = parseFloat(
                        costHoursElement[departments[a].nome]
                      );
                    }

                    if (getNameFromId() === "Avença") {
                      // newHoras =
                      //   newHoras *
                      //   workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                      //     .horas[m].horasPropostasArray.length;
                    }
                    var value1;
                    var value2;

                    if (
                      currentCollaborator != null &&
                      currentCollaborator != undefined
                    ) {
                      value1 = newHoras * currentCollaborator.precoCompra;
                      value2 = value1 / gpd;
                    }

                    if (value2 > parseFloat(currentHours.valorVendaHoras)) {
                      factureArrayVerify[0][departments[a].nome] =
                        factureArrayVerify[0][departments[a].nome] +
                        parseFloat(currentHours.valorVendaHoras);
                      factureArrayVerify[0].total =
                        factureArrayVerify[0].total +
                        parseFloat(currentHours.valorVendaHoras);
                    } else {
                      factureArrayVerify[0][departments[a].nome] =
                        factureArrayVerify[0][departments[a].nome] +
                        parseFloat(value2);
                      factureArrayVerify[0].total =
                        factureArrayVerify[0].total + parseFloat(value2);
                    }
                  }
                }
              }

              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .fornecedores.length;
                m++
              ) {
                var currentSuppliers = global.structuredClone(
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .fornecedores[m]
                );
                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentSuppliers.departamentoForn,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    factureArray[1].total =
                      factureArray[1].total +
                      parseFloat(currentSuppliers.valorVendaForn);

                    factureArray[1][departments[a].nome] =
                      factureArray[1][departments[a].nome] +
                      parseFloat(currentSuppliers.valorVendaForn);

                    costArray[0].total =
                      costArray[0].total +
                      parseFloat(currentSuppliers.precoCompraForn);

                    costArray[0][departments[a].nome] =
                      costArray[0][departments[a].nome] +
                      parseFloat(currentSuppliers.precoCompraForn);

                    costArrayVerify[0].total =
                      costArrayVerify[0].total +
                      parseFloat(currentSuppliers.precoCompraForn);

                    costArrayVerify[0][departments[a].nome] =
                      costArrayVerify[0][departments[a].nome] +
                      parseFloat(currentSuppliers.precoCompraForn);

                    if (
                      currentSuppliers.precoCompraForn != 0 &&
                      currentSuppliers.precoCompraForn != null &&
                      currentSuppliers.precoCompraForn != undefined
                    ) {
                      const currentSupplier = providerTypes.find(
                        (x) =>
                          x.idTipoFornecedor === currentSuppliers.tipoFornecedor
                      );

                      factureArrayVerify[1].total =
                        factureArrayVerify[1].total +
                        parseFloat(
                          currentSuppliers.precoCompraForn /
                            (1 - currentSupplier.percentagem / 100)
                        );

                      factureArrayVerify[1][departments[a].nome] =
                        factureArrayVerify[1][departments[a].nome] +
                        parseFloat(
                          currentSuppliers.precoCompraForn /
                            (1 - currentSupplier.percentagem / 100)
                        );
                    }
                  }
                }
              }
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .despesas.length;
                m++
              ) {
                var currentCosts = global.structuredClone(
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .despesas[m]
                );
                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentCosts.departamentoDesp,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    factureArray[2].total =
                      factureArray[2].total +
                      parseFloat(currentCosts.valorVendaDesp);

                    factureArray[2][departments[a].nome] =
                      factureArray[2][departments[a].nome] +
                      parseFloat(currentCosts.valorVendaDesp);

                    costArray[1].total =
                      costArray[1].total +
                      parseFloat(currentCosts.precoCompraForn);

                    costArray[1][departments[a].nome] =
                      costArray[1][departments[a].nome] +
                      parseFloat(currentCosts.precoCompraForn);

                    if (
                      currentCosts.precoCompraForn != 0 &&
                      currentCosts.precoCompraForn != null &&
                      currentCosts.precoCompraForn != undefined
                    ) {
                      const currentExpense = expenditureTypes.find(
                        (x) =>
                          x.idTipoDespesa === parseInt(currentCosts.tipoDespesa)
                      );
                      factureArrayVerify[2].total =
                        factureArrayVerify[2].total +
                        parseFloat(
                          currentCosts.precoCompraForn /
                            (1 - currentExpense.percentagem / 100)
                        );

                      factureArrayVerify[2][departments[a].nome] =
                        factureArrayVerify[2][departments[a].nome] +
                        parseFloat(
                          currentCosts.precoCompraForn /
                            (1 - currentExpense.percentagem / 100)
                        );

                      costArrayVerify[1].total =
                        costArrayVerify[1].total +
                        parseFloat(currentCosts.precoCompraForn);

                      costArrayVerify[1][departments[a].nome] =
                        costArrayVerify[1][departments[a].nome] +
                        parseFloat(currentCosts.precoCompraForn);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    var totalCostProportionElement = {};
    var totalCostProportionElementVerify = {};
    totalCostProportionElement.description = "DESCRIÇÃO";
    for (var i = 0; i < departments.length; i++) {
      totalCostProportionElement[departments[i].nome] = 0;
      totalCostProportionElementVerify[departments[i].nome] = 0;
    }
    totalCostProportionElement.total = 0;
    totalCostProportionElementVerify.total = 0;

    for (var i = 0; i < factureArray.length - 1; i++) {
      Object.keys(factureArray[i]).map(
        (key, index) =>
          (totalCostProportionElement[key] =
            totalCostProportionElement[key] + factureArray[i][key])
      );

      Object.keys(factureArrayVerify[i]).map(
        (key, index) =>
          (totalCostProportionElementVerify[key] =
            totalCostProportionElementVerify[key] + factureArrayVerify[i][key])
      );
    }

    var factName = convertIdToName(
      workplans[workplanIndex].formaPagamento,
      "paymentTypes"
    );
    const fact = factoring.find((x) => x.nome === factName);
    if (fact != null && fact != undefined) {
      const juro =
        ((totalCostProportionElement.total *
          1.23 *
          (fact.percentAdiantamento / 100) *
          (fact.txJuroEfetiva / 100)) /
          360) *
        parseFloat(workplans[workplanIndex].prazoPagamento);
      const impstSJuro = juro * (fact.impostoSeloComissao / 100);
      const comGestao =
        totalCostProportionElement.total * 1.23 * (fact.comissaoGestao / 100);
      const impsCJuro = comGestao * (fact.impostoSeloComissao / 100);
      const totalFactoring = juro + impsCJuro + impstSJuro + comGestao;

      const juroVerify =
        ((totalCostProportionElementVerify.total *
          1.23 *
          (fact.percentAdiantamento / 100) *
          (fact.txJuroEfetiva / 100)) /
          360) *
        parseFloat(workplans[workplanIndex].prazoPagamento);
      const impstSJuroVerify = juroVerify * (fact.impostoSeloComissao / 100);
      const comGestaoVerify =
        totalCostProportionElementVerify.total *
        1.23 *
        (fact.comissaoGestao / 100);
      const impsCJuroVerify =
        comGestaoVerify * (fact.impostoSeloComissao / 100);
      const totalFactoringVerify =
        juroVerify + impsCJuroVerify + impstSJuroVerify + comGestaoVerify;

      Object.keys(costArray[2]).map((key, index) => {
        if (key != "total")
          costArray[2][key] =
            costArray[2][key] +
            parseFloat(
              (totalCostProportionElement[key] /
                totalCostProportionElement.total) *
                totalFactoring
            );
        if (key != "description" && key != "total")
          costArray[2].total = costArray[2].total + costArray[2][key];
      });

      Object.keys(costArrayVerify[2]).map((key, index) => {
        if (key != "total")
          costArrayVerify[2][key] =
            costArrayVerify[2][key] +
            parseFloat(
              (totalCostProportionElementVerify[key] /
                totalCostProportionElementVerify.total) *
                totalFactoringVerify
            );

        if (key != "description" && key != "total")
          costArrayVerify[2].total =
            costArrayVerify[2].total + costArrayVerify[2][key];
      });

      costArrayVerify[2].description = "Factoring";
      costArray[2].description = "Factoring";

      if (fact && workplans[workplanIndex].aceitarFormaPagamento === "Sim") {
        Object.keys(costArray[2]).map(
          (key, index) =>
            (factureArray[3][key] = costArray[2][key] / (1 - fact.margemPV))
        );

        Object.keys(costArrayVerify[2]).map(
          (key, index) =>
            (factureArrayVerify[3][key] =
              costArrayVerify[2][key] / (1 - fact.margemPV))
        );
      }
      factureArray[3].description = "Factoring";
      factureArrayVerify[3].description = "Factoring";
    }
  }

  useEffect(() => {
    if (Object.keys(workplanById).length > 0) {
      const workplanByIdCopyUpdated = { ...workplanById };
      delete workplanByIdCopyUpdated.lock;
      delete workplanByIdCopyUpdated.resumoFinaceiro.toChange;
      setWorkplanByIdCopy(workplanByIdCopyUpdated);
    }
  }, [workplanById]);   

  useEffect(() => {
    if (workplans[workplanIndex] !== undefined) {
      const shouldUnlockAndDispatch = checkAdminAndProposal(
        workplans[workplanIndex].estado
      );
            if (shouldUnlockAndDispatch && !["Ganho", "Ongoing"].includes(workplans[workplanIndex].estado)) {
        setLetShowButtons(true);
      }      
      dispatch(
        getWorkplanById({
          params: {
            workPlanId: workplans[workplanIndex]?.id,
          },
        })
      );
    }
  }, [workplans[workplanIndex]]);    

  useEffect(() => {
    if(comeFromSave){
      dispatch(
        getWorkplanById({
          params: {
            workPlanId: workplans[workplanIndex]?.id,
          },
        })
      );
    }
  }, [comeFromSave]);

  useEffect(() => {
    if (formdata && didSubmit) {
      setDidSubmit(false);
      if (iflowTriggerLoading != null) {
        if (iflowTriggerLoading) {
          dispatch(
            getDocumentByWorkplan({ workPlanId: workplans[workplanIndex].id })
          );
          setProposalDisabled(false);
          setIsModalWaitingShowing(false);
          setIsModalShowing(true);
          const array =
            JSON.parse(localStorage.getItem("submittedWorkplans")) || [];
          array[workplanIndex] = 1;
          localStorage.setItem("submittedWorkplans", JSON.stringify(array));
          calculate();
        } else {
          setIsModalWaitingShowing(false);
          setIsModalSubmitErrorShowing(true);
        }
        dispatch(setIflowTriggerLoading(null));
      }
      dispatch(setIflowFormdata(null));
    }
  }, [formdata, iflowTriggerLoading, didSubmit]);

  useEffect(() => {
    if (user && user.organization && user.organization.id)
      dispatch(getBudgetmgrConfiguration(user.organization.id));
  }, [user]);

  const [isWaiting, setIsWaiting] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsWaiting(false);
    }, 5000);
  }, []);

  const checkAdminAndProposal = (plan) => {
    if (plan === "Ongoing" || plan === "Ganho") {
      if (letShowButtons) return true;

      if (plan !== workplanByIdCopy.estado) {
        return true;
      }
      if (isOrgGroupAdmin()) {
        return true;
      } else if (proposalDisabled === false) {
        return false;
      } else return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    
    const currentWorkplan = workplans[workplanIndex];

    // Inicializa 'resumoFinaceiro' se for null
    if (!currentWorkplan.resumoFinaceiro) {
      currentWorkplan.resumoFinaceiro = {
        resumeFactoration: [],
        resumeCost: [],
        resumeIncome: [],
        resumePercIncome: [],
        resumeCostHours: [],
        resumeHours: [],
        resumeMargem: [],
        resumePercMargin: [],
        toChange: 0,
        canSave: true,
        maxFactTotal: 0,
      };
    }

    // Inicializa 'simulador' se for null
    if (!currentWorkplan.simulador) {
      currentWorkplan.simulador = {
        valorLimite: 0,
        horasPropInicial: 0,
        horasValSugerido: 0,
        horasOutroValor: 0,
        horasValorConsiderar: 0,
        subcontratosPropInicial: 0,
        subcontratosValSugerido: 0,
        subcontratosOutroValor: 0,
        subcontratosValorConsiderar: 0,
        despesasPropInicial: 0,
        despesasValSugerido: 0,
        despesasOutroValor: 0,
        despesasValorConsiderar: 0,
        factoringPropInicial: 0,
        factoringValSugerido: 0,
        factoringOutroValor: 0,
        factoringValorConsiderar: 0,
        totalPropInicial: 0,
        totalValSugerido: 0,
        totalOutroValor: 0,
        totalValorConsiderar: 0,
      };
    }

    // Atualiza o 'lock' e salva as alterações se necessário
    if (Object.keys(workplanById).length > 0) {
      const shouldUnlockAndDispatch = checkAdminAndProposal(
        currentWorkplan.estado
      );

      if (shouldUnlockAndDispatch && !comeFromSave) {
        currentWorkplan.lock = workplanById.lock || user.username;
        currentWorkplan.resumoFinaceiro.toChange = 0;
        currentWorkplan.submitted = workplanById.submitted;
        dispatch(
          saveChangesWorkplan({ content: currentWorkplan, show: false })
        );
        setIsLocktoUser(currentWorkplan.lock);
      }
    }
  }, [workplanById]);

  useEffect(() => {
    if (errorUpdating != null) {
      const array =
        JSON.parse(localStorage.getItem("submittedWorkplans")) || [];
      array[workplanIndex] = 0;
      localStorage.setItem("submittedWorkplans", JSON.stringify(array));
      dispatch(setErrorUpdating(null));

      if (errorUpdating === true) {
        setIsModalErrorUpdatingShowing(true);
      } else if (!comeFromSave && !errorUpdating) {
        setIsModalSaveShowing(false);
        const flowId = budgetmgrConfiguration?.flowId || "337";
        const payload = {
          url: `/inicio_flow.jsp?flowid=${flowId}&attr_workplanId=${
            workplans[workplanIndex].id
          }&attr_Authorization=${oauth.getAccessToken()}&Authorization=${oauth.getAccessToken()}`,
        };
        // setIsModalWaitingShowing(true);
        // setProposalDisabled(true);
        // setDidSubmit(true);
        dispatch(getIflowFormdata(payload));
      } else {
        setIsModalSaveShowing(true);
      }
    }
  }, [workplan, workplans, errorUpdating, comeFromSave]);

  useEffect(() => {
    if (isLockUser != "") {
      if (user?.username == isLockUser) {
        setIsLock(true);
      } else {
        setIsLock(false);
      }
    }
  }, [workplans]);

  const newItem = {
    nomeObjetivo: "",
    valorCustoObj: 0,
    valorVendaObj: 0,
    objetivoEspecifico: "",
    atividades: [
      {
        nomeAtividade: "",
        valorCustoAct: 0,
        valorVendaAct: 0,
        duracaoAct: 0,
        dataInicioAct: "",
        dataFimAct: "",
        opcoes: [
          {
            selected: false,
            imprimir: false,
            nomeOpcao: "",
            valorCustoOpt: 0,
            valorVendaOpt: 0,
            observaceosOpt: "",
            imagensOpt: [],
            horas: [],
            fornecedores: [],
            despesas: [],
          },
        ],
      },
    ],
    createdDate: null,
    createdBy: "kate",
  };

  function addItem() {
    workplans[workplanIndex].items.push(newItem);
    dispatch(setAllWorkplans(workplans));
  }

  function updateItemFunction(submited, removeLock) {
    if (
      workplans[workplanIndex].numeroGanho === "" &&
      (workplans[workplanIndex].estado === "Ganho" ||
        workplans[workplanIndex].estado === "Ongoing")
    ) {
      setIsModalProjectNumberErrorShowing(true);
      return;
    }
    if (workplans[workplanIndex].resumoFinaceiro.canSave === false) {
      setIsModalShowingErrorFact(true);
      return;
    }
    setProposalDisabled(true);

    callRecalculate();
    workplans[workplanIndex].submitted = submited;
    if (removeLock) {
      workplans[workplanIndex].lock = "";
    }
    delete workplans[workplanIndex].isChanged;
    dispatch(setWorkplanById(workplans[workplanIndex]));
    dispatch(
      saveChangesWorkplan({ content: workplans[workplanIndex], show: true })
    );
    // dispatch(getAllWorkplansByOrgGroup());

    // dispatch(
    //   getDocumentByWorkplan({ workPlanId: workplans[workplanIndex].id })
    // );

    setCalculateGoBackFunction(false);
  }
  const months = [
    "jan",
    "feb",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "out",
    "nov",
    "dec",
  ];
  const monthsBtwnDates = (startDate, endDate) => {
    if (startDate === null || endDate === null) return;
    var newStart = new Date(startDate);
    var newEnd = new Date(endDate);
    newStart = new Date(
      newStart.getTime() - newStart.getTimezoneOffset() * +60000
    );
    newEnd = new Date(newEnd.getTime() - newEnd.getTimezoneOffset() * +60000);
    var startStr = newStart.toISOString();
    var endStr = newEnd.toISOString();

    startStr = startStr.slice(0, 10);
    endStr = endStr.slice(0, 10);
    var start = startStr.split("-");
    var end = endStr.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]);
      var startMon = i === startYear ? parseInt(start[1]) : 0;

      for (var j = startMon; j <= endMonth; j++) {
        var month = j;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }

    return dates;
  };
  function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split("e");
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
    // Shift back
    value = value.toString().split("e");
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
  }

  function setCallCalculateHours(itemIndex, activityIndex, optionIndex) {
    var currentColabId = "";
    const filteredNewcollaborators = workplans[workplanIndex]?.isNew
      ? collaborators.filter((item) => item.estado === 1)
      : collaborators;
    const filteredNewexpenses = workplans[workplanIndex]?.isNew
      ? expenses.filter((item) => item.estado === 1)
      : expenses;
    var pc = 1.0;
    var pv = 1.0;

    if (
      filteredNewcollaborators?.length != 0 &&
      filteredNewcollaborators != null &&
      filteredNewcollaborators != undefined
    ) {
      const colab = filteredNewcollaborators.find(
        (x) => x.idColaborador === parseInt(currentColabId)
      );

      if (colab != null) {
        pc = colab.precoCompra;
        pv = colab.precoVenda;
      }
    } else {
      pc = 0;
      pv = 0;
    }

    if (
      filteredNewexpenses?.length != 0 &&
      filteredNewexpenses != null &&
      filteredNewexpenses != undefined
    ) {
      const expGPI = filteredNewexpenses.find(
        (x) => x.tipo === "Gastos Pessoal Indireto (GPI)"
      );
      if (expGPI != null) gpi = expGPI.percentagem / 100;

      const expFSE = filteredNewexpenses.find((x) => x.tipo === "FSE");
      if (expFSE != null) fse = expFSE.percentagem / 100;
    } else {
      fse = 0;
      gpi = 0;
    }
    var newOptionCostValue = 0;
    var newOptionSaleValue = 0;
    const currentHoras =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].horas;
    const currentForn =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].fornecedores;
    const currentDespesas =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].despesas;
    for (var i = 0; i < currentHoras.length; i++) {
      newOptionCostValue += parseFloat(currentHoras[i].custosHoras);
      newOptionSaleValue += parseFloat(currentHoras[i].valorVendaHoras);
    }
    for (var i = 0; i < currentForn.length; i++) {
      newOptionCostValue += parseFloat(currentForn[i].precoCompraForn);
      newOptionSaleValue += parseFloat(currentForn[i].valorVendaForn);
    }
    for (var i = 0; i < currentDespesas.length; i++) {
      newOptionCostValue += parseFloat(currentDespesas[i].precoCompraForn);
      newOptionSaleValue += parseFloat(currentDespesas[i].valorVendaDesp);
    }

    if (!(newOptionCostValue instanceof String))
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].valorCustoOpt = newOptionCostValue?.toFixed(2);

    workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
      optionIndex
    ].valorVendaOpt = newOptionSaleValue?.toFixed(2);

    var currentActivityCost = 0;
    var currentActivitySale = 0;

    var currentItemCost = 0;
    var currentItemSale = 0;

    const currentOptions =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes;

    for (var i = 0; i < currentOptions.length; i++) {
      if (currentOptions[i].selected) {
        currentActivityCost += parseFloat(currentOptions[i].valorCustoOpt);
        currentActivitySale += parseFloat(currentOptions[i].valorVendaOpt);
      }
    }

    if (currentActivityCost === 0 && currentActivitySale === 0) {
      currentActivityCost = parseFloat(currentOptions[0].valorCustoOpt);
      currentActivitySale = parseFloat(currentOptions[0].valorVendaOpt);
    }

    workplans[workplanIndex].items[itemIndex].atividades[
      activityIndex
    ].valorCustoAct = currentActivityCost;
    workplans[workplanIndex].items[itemIndex].atividades[
      activityIndex
    ].valorVendaAct = currentActivitySale;

    const currentActivities =
      workplans[workplanIndex].items[itemIndex].atividades;

    for (var i = 0; i < currentActivities.length; i++) {
      currentItemCost += parseFloat(currentActivities[i].valorCustoAct);
      currentItemSale += parseFloat(currentActivities[i].valorVendaAct);
    }

    workplans[workplanIndex].items[itemIndex].valorCustoObj =
      parseFloat(currentItemCost);
    workplans[workplanIndex].items[itemIndex].valorVendaObj =
      parseFloat(currentItemSale);

    devLogConsole(workplans[workplanIndex].items[itemIndex]);
    dispatch(setAllWorkplans(workplans));
  }

  function callCalculateHours(index) {
    workplans[workplanIndex].isChanged = true;
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;
    if (workplans[0]) {
      const items = workplans[workplanIndex]?.items || workplans;
      items.forEach((item, itemIndex) => {
        if (item.atividades && Object.keys(item.atividades).length !== 0) {
          item.atividades.forEach((atividade, activityIndex) => {
            if (
              atividade.opcoes &&
              Object.keys(atividade.opcoes).length !== 0
            ) {
              atividade.opcoes.forEach((opcao, optionIndex) => {
                setCallCalculateHours(itemIndex, activityIndex, optionIndex);
              });
            }
          });
        }
      });
    }
  }

  const callRecalculate = () => {
    if (
      workplans[workplanIndex].resumoFinaceiro.resumeFactoration.length === 0
    ) {
      callCalculateHours();
    }
    workplans[workplanIndex].isChanged = true;
    for (var i = 0; i < workplans[workplanIndex]?.items?.length; i++) {
      for (
        var k = 0;
        k < workplans[workplanIndex].items[i].atividades.length;
        k++
      ) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades[k].opcoes.length;
          j++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[k].opcoes[j].selected
          ) {
            totalOptSelectedCostValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorCustoOpt
            );
            totalOptSelectedSaleValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorVendaOpt
            );
          }
          if (
            workplans[workplanIndex].items[i].atividades[k].opcoes.length < 2
          ) {
            totalOpt2CostValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[0]
                .valorCustoOpt
            );
            totalOpt2SaleValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[0]
                .valorVendaOpt
            );
          }
          if (
            workplans[workplanIndex].items[i].atividades[k].opcoes.length < 3 &&
            j === 0
          ) {
            totalOpt3CostValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[0]
                .valorCustoOpt
            );
            totalOpt3SaleValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[0]
                .valorVendaOpt
            );
          }
          if (j === 0) {
            totalOpt1CostValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorCustoOpt
            );
            totalOpt1SaleValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorVendaOpt
            );
          } else if (j === 1) {
            setShowOption2(true);
            totalOpt2CostValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorCustoOpt
            );
            totalOpt2SaleValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorVendaOpt
            );
          } else if (j === 2) {
            setShowOption3(true);
            totalOpt3CostValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorCustoOpt
            );
            totalOpt3SaleValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorVendaOpt
            );
          }
        }
      }
    }

    setOption1CostValue(totalOpt1CostValue);
    setOption1SaleValue(totalOpt1SaleValue);
    setOption1FinalMargin((totalOpt1SaleValue - totalOpt1CostValue).toFixed(2));

    setOption2CostValue(totalOpt2CostValue);
    setOption2SaleValue(totalOpt2SaleValue);
    setOption2FinalMargin((totalOpt2SaleValue - totalOpt2CostValue).toFixed(2));

    setOption3CostValue(totalOpt3CostValue);
    setOption3SaleValue(totalOpt3SaleValue);
    setOption3FinalMargin((totalOpt3SaleValue - totalOpt3CostValue).toFixed(2));

    setOptionSelectedCostValue(totalOptSelectedCostValue);
    setOptionSelectedSaleValue(totalOptSelectedSaleValue);
    setOptionSelectedFinalMargin(
      (totalOptSelectedSaleValue - totalOptSelectedCostValue).toFixed(2)
    );

    if (expenses?.length != 0 && expenses != null && expenses != undefined) {
      const expGPI = expenses.find(
        (x) => x.tipo === "Gastos Pessoal Indireto (GPI)"
      );
      if (expGPI != null) gpi = expGPI.percentagem / 100;

      const expFSE = expenses.find((x) => x.tipo === "FSE");
      if (expFSE != null) fse = expFSE.percentagem / 100;

      const expGPD = expenses.find(
        (x) => x.tipo === "Gastos Pessoal Direto (GPD)"
      );
      if (expGPD != null) gpd = expGPD.percentagem / 100;
    } else {
      fse = 0;
      gpi = 0;
      gpd = 0;
    }

    var factureHoursElement = {};
    var factureSubElement = {};
    var factureCostElement = {};
    var facturFactElement = {};

    var factureHoursVerifyElement = {};
    var factureSubVerifyElement = {};
    var factureCostVerifyElement = {};
    var facturFactVerifyElement = {};

    var titleElement = {};
    var totalFactureElement = {};
    var totalCostElement = {};

    var totalFactureElementVerify = {};
    var totalCostElementVerify = {};

    var costSubElement = {};
    var costCostElement = {};
    var costFactElement = {};

    var costSubVerifyElement = {};
    var costCostVerifyElement = {};
    var costFactVerifyElement = {};

    var incomeElement = {};
    var percentIncomeElement = {};

    var marginElement = {};
    var initialMarginElement = {};
    var percentMarginElement = {};

    var incomeElementVerify = {};
    var percentIncomeElementVerify = {};
    var marginElementVerify = {};
    var initialMarginElementVerify = {};
    var percentMarginElementVerify = {};
    var marginOkElement = {};

    marginOkElement.description = "MARGEM OK/NOT";
    titleElement.description = "Descriçao";

    initialMarginElementVerify.description = "Margem";

    factureHoursVerifyElement.description = "Horas";
    factureSubVerifyElement.description = "Subcontratos";
    factureCostVerifyElement.description = "Despesas Admin";
    facturFactVerifyElement.description = "Factoring";

    factureHoursElement.description = "Horas";
    factureSubElement.description = "Subcontratos";
    factureCostElement.description = "Despesas Admin";
    facturFactElement.description = "Factoring";

    costSubElement.description = "Subcontratos";
    costCostElement.description = "Despesas Admin";
    costFactElement.description = "Factoring";

    costSubVerifyElement.description = "Subcontratos";
    costCostVerifyElement.description = "Despesas Admin";
    costFactVerifyElement.description = "Factoring";

    totalFactureElement.description = "Total a Faturar";
    totalCostElement.description = "Total Custos";

    totalFactureElementVerify.description = "Total a Faturar";
    totalCostElementVerify.description = "Total Custos";

    incomeElement.description = "Income";
    percentIncomeElement.description = "";

    costHoursElement.description = "Custo Horas";
    hoursElement.description = "Horas";

    marginElement.description = "Margem";
    initialMarginElement.description = "Margem";
    percentMarginElement.description = "";

    incomeElementVerify.description = "Income";
    percentIncomeElementVerify.description = "";
    marginElementVerify.description = "Margem";
    percentMarginElementVerify.description = "";

    for (var i = 0; i < departments.length; i++) {
      titleElement[departments[i].nome] = [departments[i].nome];

      marginOkElement[departments[i].nome] = 0;

      factureHoursElement[departments[i].nome] = 0;
      factureSubElement[departments[i].nome] = 0;
      factureCostElement[departments[i].nome] = 0;
      facturFactElement[departments[i].nome] = 0;

      initialMarginElementVerify[departments[i].nome] = 0;

      factureHoursVerifyElement[departments[i].nome] = 0;
      factureSubVerifyElement[departments[i].nome] = 0;
      factureCostVerifyElement[departments[i].nome] = 0;
      facturFactVerifyElement[departments[i].nome] = 0;

      initialMarginElement[departments[i].nome] = 0;

      costSubElement[departments[i].nome] = 0;
      costCostElement[departments[i].nome] = 0;
      costFactElement[departments[i].nome] = 0;

      costSubVerifyElement[departments[i].nome] = 0;
      costCostVerifyElement[departments[i].nome] = 0;
      costFactVerifyElement[departments[i].nome] = 0;

      totalFactureElement[departments[i].nome] = 0;
      totalCostElement[departments[i].nome] = 0;

      totalFactureElementVerify[departments[i].nome] = 0;
      totalCostElementVerify[departments[i].nome] = 0;

      incomeElement[departments[i].nome] = 0;
      percentIncomeElement[departments[i].nome] = 0;

      costHoursElement[departments[i].nome] = 0;
      hoursElement[departments[i].nome] = 0;

      marginElement[departments[i].nome] = 0;
      percentMarginElement[departments[i].nome] = 0;

      incomeElementVerify[departments[i].nome] = 0;
      percentIncomeElementVerify[departments[i].nome] = 0;

      marginElementVerify[departments[i].nome] = 0;
      percentMarginElementVerify[departments[i].nome] = 0;
    }

    titleElement.total = "Total";

    marginOkElement.total = 0;

    initialMarginElementVerify.total = 0;

    initialMarginElement.total = 0;

    costHoursElement.total = 0;

    hoursElement.total = 0;

    percentIncomeElement.total = 0;
    incomeElement.total = 0;
    percentMarginElement.total = 0;
    marginElement.total = 0;

    percentIncomeElementVerify.total = 0;
    incomeElementVerify.total = 0;
    percentMarginElementVerify.total = 0;
    marginElementVerify.total = 0;

    factureHoursElement.total = 0;
    factureSubElement.total = 0;
    factureCostElement.total = 0;
    facturFactElement.total = 0;

    factureHoursVerifyElement.total = 0;
    factureSubVerifyElement.total = 0;
    factureCostVerifyElement.total = 0;
    facturFactVerifyElement.total = 0;

    totalFactureElement.total = 0;

    totalFactureElementVerify.total = 0;

    costSubElement.total = 0;
    costCostElement.total = 0;
    costFactElement.total = 0;

    costSubVerifyElement.total = 0;
    costCostVerifyElement.total = 0;
    costFactVerifyElement.total = 0;

    totalCostElement.total = 0;

    totalCostElementVerify.total = 0;

    factureArray.push(factureHoursElement);
    factureArray.push(factureSubElement);
    factureArray.push(factureCostElement);
    factureArray.push(facturFactElement);

    factureArrayVerify.push(factureHoursVerifyElement);
    factureArrayVerify.push(factureSubVerifyElement);
    factureArrayVerify.push(factureCostVerifyElement);
    factureArrayVerify.push(facturFactVerifyElement);

    costArray.push(costSubElement);
    costArray.push(costCostElement);
    costArray.push(costFactElement);

    costArrayVerify.push(costSubVerifyElement);
    costArrayVerify.push(costCostVerifyElement);
    costArrayVerify.push(costFactVerifyElement);

    buildHoursToFacture();
    setFactureArrayState(factureArray);
    setCostArrayState(costArray);

    for (var i = 0; i < factureArray.length; i++) {
      Object.keys(factureArray[i]).map((key, index) => {
        if (key === "total") {
          //devLogConsole(factureArray[i][key], factureArray[i]);

          totalFactureElement[key] =
            parseFloat(totalFactureElement[key]) +
            parseFloat(factureArray[i][key].toFixed(2));
        } else {
          totalFactureElement[key] =
            totalFactureElement[key] + factureArray[i][key];
        }
      });
    }
    //devLogConsole(totalFactureElement.total);

    for (var i = 0; i < factureArrayVerify.length; i++) {
      Object.keys(factureArrayVerify[i]).map(
        (key, index) =>
          (totalFactureElementVerify[key] =
            totalFactureElementVerify[key] + factureArrayVerify[i][key])
      );
    }

    for (var i = 0; i < costArray.length; i++) {
      Object.keys(costArray[i]).map(
        (key, index) =>
          (totalCostElement[key] = totalCostElement[key] + costArray[i][key])
      );
    }

    for (var i = 0; i < costArrayVerify.length; i++) {
      Object.keys(costArrayVerify[i]).map(
        (key, index) =>
          (totalCostElementVerify[key] =
            totalCostElementVerify[key] + costArrayVerify[i][key])
      );
    }

    Object.keys(totalFactureElement).map(
      (key, index) =>
        (incomeElement[key] =
          parseFloat(totalFactureElement[key]) -
          parseFloat(totalCostElement[key]))
    );

    Object.keys(costHoursElement).map((key, index) => {
      if (key != "total" && key != "description") {
        costHoursElement.total = costHoursElement.total + costHoursElement[key];
      }
    });

    Object.keys(hoursElement).map((key, index) => {
      if (key != "total" && key != "description") {
        const hoursStringArray = hoursElement[key].toString().split(".");
        var minutes = "0." + hoursStringArray[1];
        minutes = Math.ceil(parseFloat(minutes) * 60);
        minutes = minutes.toString();
        if (minutes.length === 1) {
          minutes = "0" + minutes;
        }
        hoursElement[key] = hoursStringArray[0] + ":" + minutes;
      }
    });

    Object.keys(hoursElement).map((key, index) => {
      if (key != "total" && key != "description") {
        const hoursArray = hoursElement[key].split(":");
        var newHoras =
          parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;
        hoursElement.total = hoursElement.total + newHoras;
      }
    });

    const hoursStringArray = hoursElement.total.toString().split(".");

    var minutes = "0." + hoursStringArray[1];
    minutes = Math.ceil(parseFloat(minutes) * 60);
    minutes = minutes.toString();
    if (minutes.length === 1) {
      minutes = "0" + minutes;
    }
    hoursElement.total = hoursStringArray[0] + ":" + minutes;

    Object.keys(totalFactureElementVerify).map(
      (key, index) =>
        (incomeElementVerify[key] =
          parseFloat(totalFactureElementVerify[key]) -
          parseFloat(totalCostElementVerify[key]))
    );

    Object.keys(incomeElement).map(
      (key, index) =>
        (percentIncomeElement[key] =
          (parseFloat(incomeElement[key]) /
            parseFloat(totalFactureElement[key])) *
          100)
    );

    Object.keys(incomeElementVerify).map((key, index) => {
      if (totalFactureElementVerify[key] != 0) {
        percentIncomeElementVerify[key] =
          (incomeElementVerify[key] / totalFactureElementVerify[key]) * 100;
      }
    });

    var didCalculateMargin = false;
    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].selected
          ) {
            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                .length;
              m++
            ) {
              var currentHours =
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ];
              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentHours.departamentoHoras,
                    "departments"
                  ) === departments[a].nome
                ) {
                  didCalculateMargin = true;
                  const currentCollaborator = collaborators.find(
                    (x) =>
                      x.idColaborador === parseInt(currentHours.colaborador)
                  );

                  const hoursArray = currentHours.horasPropostas.split(":");
                  var newHoras =
                    parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;

                  if (getNameFromId() === "Avença") {
                    //newHoras =
                    //  newHoras *
                    //  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    //    .horas[m].horasPropostasArray.length;
                  }

                  var value1;
                  var value2;

                  if (
                    currentCollaborator != null &&
                    currentCollaborator != undefined
                  ) {
                    value1 = newHoras * currentCollaborator.precoCompra;
                    value2 =
                      value1 +
                      factureArrayVerify[0][departments[a].nome] * fse +
                      factureArrayVerify[0][departments[a].nome] * gpi;
                  }

                  initialMarginElementVerify[departments[a].nome] =
                    initialMarginElementVerify[departments[a].nome] + value2;

                  initialMarginElement[departments[a].nome] =
                    initialMarginElement[departments[a].nome] +
                    currentHours.custosHoras;
                }
              }
            }
          }
        }
      }
    }

    if (didCalculateMargin === false) {
      for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades.length;
          j++
        ) {
          for (
            var k = 0;
            k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                  .length;
                m++
              ) {
                var currentHours =
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .horas[m];
                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentHours.departamentoHoras,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    didCalculateMargin = true;
                    const currentCollaborator = collaborators.find(
                      (x) =>
                        x.idColaborador === parseInt(currentHours.colaborador)
                    );

                    const hoursArray = currentHours.horasPropostas.split(":");
                    var newHoras =
                      parseFloat(hoursArray[0]) +
                      parseFloat(hoursArray[1]) / 60;

                    if (getNameFromId() === "Avença") {
                      // newHoras =
                      //   newHoras *
                      //   workplans[workplanIndex].items[i].atividades[j].opcoes[
                      //     k
                      //   ].horas[m].horasPropostasArray.length;
                    }
                    var value1;
                    var value2;
                    if (
                      currentCollaborator != null &&
                      currentCollaborator != undefined
                    ) {
                      value1 = newHoras * currentCollaborator.precoCompra;
                      value2 =
                        value1 +
                        factureArrayVerify[0][departments[a].nome] * fse +
                        factureArrayVerify[0][departments[a].nome] * gpi;
                    }

                    initialMarginElementVerify[departments[a].nome] =
                      initialMarginElementVerify[departments[a].nome] + value2;

                    initialMarginElement[departments[a].nome] =
                      initialMarginElement[departments[a].nome] +
                      currentHours.custosHoras;
                  }
                }
              }
            }
          }
        }
      }
    }

    Object.keys(initialMarginElement).map((key, index) => {
      if (key != "total" && key != "description") {
        marginElement[key] =
          parseFloat(incomeElement[key]) -
          parseFloat(initialMarginElement[key]);

        marginElement.total +=
          parseFloat(incomeElement[key]) -
          parseFloat(initialMarginElement[key]);
      }
    });

    Object.keys(initialMarginElementVerify).map((key, index) => {
      if (
        incomeElementVerify[key] != 0 &&
        key != "total" &&
        key != "description"
      ) {
        marginElementVerify[key] =
          parseFloat(incomeElementVerify[key]) -
          initialMarginElementVerify[key];

        marginElementVerify.total +=
          parseFloat(incomeElementVerify[key]) -
          parseFloat(initialMarginElementVerify[key]);
      }
    });

    Object.keys(marginElement).map(
      (key, index) =>
        (percentMarginElement[key] =
          (parseFloat(marginElement[key]) /
            parseFloat(totalFactureElement[key])) *
          100)
    );

    Object.keys(marginElementVerify).map(
      (key, index) =>
        (percentMarginElementVerify[key] =
          (marginElementVerify[key] / totalFactureElementVerify[key]) * 100)
    );

    Object.keys(percentMarginElementVerify).map((key, index) => {
      if (incomeElement[key] === 0) {
        marginOkElement[key] = -1;
      } else if (
        percentMarginElement[key].toFixed(2) <
        percentMarginElementVerify[key].toFixed(2)
      ) {
        marginOkElement[key] = 0;
      } else {
        marginOkElement[key] = 1;
      }
    });

    var monthElement = {};
    monthElement.description = "Rubrica";

    const currentMonths = monthsBtwnDates(
      workplans[workplanIndex].dataInicioProjeto,
      workplans[workplanIndex].dataFinalProjeto
    );

    if (currentMonths === null || currentMonths === undefined) return;
    devLogConsole(currentMonths);
    devLogConsole(months);
    for (var i = 0; i < currentMonths.length; i++) {
      if (currentMonths[i].split("-")[1] === "00") {
        var newYear = parseInt(currentMonths[i].split("-")[0]) - 1;
        monthElement[currentMonths[i]] = months[11] + "/" + newYear;
      } else {
        monthElement[currentMonths[i]] =
          months[parseInt(currentMonths[i].split("-")[1]) - 1] +
          "/" +
          currentMonths[i].split("-")[0];
      }
    }

    monthElement.total = "Total";

    var newTotalFactureElement = {};
    newTotalFactureElement.description = "Faturação";
    var totalFaturacao = 0;

    if (workplans[workplanIndex].resumoFinaceiro.toChange === 1) {
      Object.keys(monthElement).map((key, index) => {
        if (key != "total" && key != "description") {
          if (getNameFromId() === "Avença") {
            newTotalFactureElement[key] = totalFactureElement.total;
            totalFaturacao =
              totalFaturacao + parseFloat(totalFactureElement.total);
          } else {
            if (
              workplans[workplanIndex].faseFinal === "Inicio" ||
              workplans[workplanIndex].faseFinal === null ||
              workplans[workplanIndex].faseFinal === "" ||
              workplans[workplanIndex].faseFinal === undefined
            ) {
              if (index === 1) {
                newTotalFactureElement[key] = totalFactureElement.total;
                totalFaturacao = totalFaturacao + totalFactureElement.total;
              } else {
                newTotalFactureElement[key] = 0;
              }
            } else if (workplans[workplanIndex].faseFinal === "Repartido") {
              //find realNumber
              const realNumber =
                parseFloat(totalFactureElement.total) / currentMonths.length;
              //find 1st number
              const firstNumber = decimalAdjust("floor", realNumber, -2);

              if (index === currentMonths.length) {
                //find last number
                const lastNumber = (
                  totalFactureElement.total -
                  firstNumber * (currentMonths.length - 1)
                ).toFixed(2);

                //string to number
                newTotalFactureElement[key] = parseFloat(
                  parseFloat(lastNumber).toFixed(2)
                );
                totalFaturacao = (
                  totalFaturacao + parseFloat(lastNumber)
                ).toFixed(2);
              } else {
                newTotalFactureElement[key] = firstNumber;
                totalFaturacao = totalFaturacao + parseFloat(firstNumber);
              }
            } else {
              if (index === currentMonths.length) {
                newTotalFactureElement[key] = totalFactureElement.total;
                totalFaturacao = totalFaturacao + totalFactureElement.total;
              } else {
                newTotalFactureElement[key] = 0;
              }
            }
          }
        }
      });

      newTotalFactureElement.total = totalFaturacao;
      workplans[workplanIndex].resumoFinaceiro.toChange = 0;
      workplans[workplanIndex].resumoFinaceiro.canSave = true;
    } else {
      Object.keys(monthElement).map((key, index) => {
        if (key != "total" && key != "description") {
          newTotalFactureElement[key] =
            workplans[workplanIndex].resumoFinaceiro.resumeFactoration[
              index - 1
            ] !== undefined
              ? workplans[workplanIndex].resumoFinaceiro.resumeFactoration[
                  index - 1
                ].value
              : 0;
        }
      });

      newTotalFactureElement.total =
        workplans[workplanIndex].resumoFinaceiro.resumeFactoration[
          workplans[workplanIndex].resumoFinaceiro.resumeFactoration.length - 1
        ].value;
    }

    var newTotalCostElement = {};
    newTotalCostElement.description = "Subcontratos e outros custos";
    var totalCustos = 0;

    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          newTotalCostElement[key] = totalCostElement.total;
          totalCustos =
            totalCustos + parseFloat(totalCostElement.total.toFixed(2));
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              newTotalCostElement[key] = totalCostElement.total;
              totalCustos = totalCustos + totalCostElement.total;
            } else {
              newTotalCostElement[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            //find realNumber
            const realNumber =
              parseFloat(totalCostElement.total) / currentMonths.length;
            //find 1st number
            const firstNumber = decimalAdjust("floor", realNumber, -2);

            if (index === currentMonths.length) {
              //find last number
              const lastNumber = (
                totalCostElement.total -
                firstNumber * (currentMonths.length - 1)
              ).toFixed(2);

              //string to number
              newTotalCostElement[key] = parseFloat(
                parseFloat(lastNumber).toFixed(2)
              );
              totalCustos = (totalCustos + parseFloat(lastNumber)).toFixed(2);
            } else {
              newTotalCostElement[key] = firstNumber;
              totalCustos = totalCustos + parseFloat(firstNumber);
            }
          } else {
            if (index === currentMonths.length) {
              newTotalCostElement[key] = totalCostElement.total;
              totalCustos = totalCustos + totalCostElement.total;
            } else {
              newTotalCostElement[key] = 0;
            }
          }
        }
      }
    });
    devLogConsole(totalCustos);
    newTotalCostElement.total = totalCustos;

    var newTotalIncomeElement = {};
    newTotalIncomeElement.description = "Income";
    var totalIncome = 0;

    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          newTotalIncomeElement[key] =
            parseFloat(newTotalFactureElement[key]) -
            parseFloat(newTotalCostElement[key]);
          totalIncome =
            totalIncome +
            parseFloat(
              parseFloat(newTotalFactureElement[key]) -
                parseFloat(newTotalCostElement[key]).toFixed(2)
            );
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              newTotalIncomeElement[key] = incomeElement.total;
              totalIncome = totalIncome + incomeElement.total;
            } else {
              newTotalIncomeElement[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            //find realNumber
            const realNumber =
              parseFloat(incomeElement.total) / currentMonths.length;
            //find 1st number
            const firstNumber = decimalAdjust("floor", realNumber, -2);

            if (index === currentMonths.length) {
              //find last number
              const lastNumber = (
                incomeElement.total -
                firstNumber * (currentMonths.length - 1)
              ).toFixed(2);

              //string to number
              newTotalIncomeElement[key] = parseFloat(
                parseFloat(lastNumber).toFixed(2)
              );
              totalIncome = (totalIncome + parseFloat(lastNumber)).toFixed(2);
            } else {
              newTotalIncomeElement[key] =
                parseFloat(newTotalFactureElement[key]) -
                parseFloat(newTotalCostElement[key]);
              totalIncome =
                totalIncome +
                parseFloat(newTotalFactureElement[key]) -
                parseFloat(newTotalCostElement[key]);
            }
          } else {
            if (index === currentMonths.length) {
              newTotalIncomeElement[key] = incomeElement.total;
              totalIncome = totalIncome + incomeElement.total;
            } else {
              newTotalIncomeElement[key] = 0;
            }
          }
        }
      }
    });
    newTotalIncomeElement.total = totalIncome;

    var newPercIncomeElement = {};
    newPercIncomeElement.description = "% Income";
    var totalPercIncome = 0;

    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          newPercIncomeElement[key] =
            (newTotalIncomeElement[key] /
              parseFloat(newTotalFactureElement[key])) *
            100;
          totalPercIncome = totalPercIncome + percentIncomeElement.total;
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              newPercIncomeElement[key] = percentIncomeElement.total;
              totalPercIncome = totalPercIncome + percentIncomeElement.total;
            } else {
              newPercIncomeElement[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            newPercIncomeElement[key] =
              (newTotalIncomeElement[key] /
                parseFloat(newTotalFactureElement[key])) *
              100;
            totalPercIncome =
              newTotalIncomeElement.total /
              parseFloat(newTotalFactureElement.total);
          } else {
            if (index === currentMonths.length) {
              newPercIncomeElement[key] = percentIncomeElement.total;
              totalPercIncome = totalPercIncome + percentIncomeElement.total;
            } else {
              newPercIncomeElement[key] = 0;
            }
          }
        }
      }
    });
    newPercIncomeElement.total = percentIncomeElement.total;

    var newCostHoursElement = {};
    newCostHoursElement.description = "Custo das Horas";
    var totalCostHours = 0;

    var totalHoursAdded = [];
    var totalCostAdded = 0;
    var hoursAvenca = 0;
    var cameToSelected = false;
    for (var i = 0; i < currentMonths.length; i++) {
      totalHoursAdded[i] = 0;
    }
    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].selected
          ) {
            cameToSelected = true;
            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                .length;
              m++
            ) {
              var currentHours =
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ];

              totalCostAdded =
                totalCostAdded + parseFloat(currentHours.custosHoras);
              hoursAvenca =
                hoursAvenca +
                parseFloat(currentHours.horasPropostas.split(":")[0]) +
                parseFloat(currentHours.horasPropostas.split(":")[1]) / 60;
              for (
                var u = 0;
                u < currentHours.horasPropostasArray.length;
                u++
              ) {
                totalHoursAdded[u] =
                  totalHoursAdded[u] +
                  parseFloat(
                    currentHours.horasPropostasArray[u].hoursValue.split(":")[0]
                  ) +
                  parseFloat(
                    currentHours.horasPropostasArray[u].hoursValue.split(":")[1]
                  ) /
                    60;
              }
            }
          }
        }
      }
    }

    if (cameToSelected === false) {
      for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades.length;
          j++
        ) {
          for (
            var k = 0;
            k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              cameToSelected = true;
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                  .length;
                m++
              ) {
                var currentHours =
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .horas[m];

                totalCostAdded =
                  totalCostAdded + parseFloat(currentHours.custosHoras);
                hoursAvenca =
                  hoursAvenca +
                  parseFloat(currentHours.horasPropostas.split(":")[0]) +
                  parseFloat(currentHours.horasPropostas.split(":")[1]) / 60;
                for (
                  var u = 0;
                  u < currentHours.horasPropostasArray.length;
                  u++
                ) {
                  devLogConsole(
                    parseFloat(
                      currentHours.horasPropostasArray[u].hoursValue.split(
                        ":"
                      )[0]
                    ) +
                      parseFloat(
                        currentHours.horasPropostasArray[u].hoursValue.split(
                          ":"
                        )[1]
                      ) /
                        60
                  );
                  totalHoursAdded[u] =
                    totalHoursAdded[u] +
                    parseFloat(
                      currentHours.horasPropostasArray[u].hoursValue.split(
                        ":"
                      )[0]
                    ) +
                    parseFloat(
                      currentHours.horasPropostasArray[u].hoursValue.split(
                        ":"
                      )[1]
                    ) /
                      60;
                }
              }
            }
          }
        }
      }
    }

    var totalHoras = 0;
    for (var i = 0; i < totalHoursAdded.length; i++) {
      totalHoras = totalHoras + totalHoursAdded[i];
    }

    devLogConsole(totalHoras);
    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          newCostHoursElement[key] = totalCostAdded;
          totalCostHours = totalCostHours + totalCostAdded;
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              newCostHoursElement[key] = totalCostAdded;
              totalCostHours = totalCostHours + totalCostAdded;
            } else {
              newCostHoursElement[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            //find realNumber
            const realNumber =
              parseFloat(totalCostAdded) / currentMonths.length;
            //find 1st number
            const firstNumber = decimalAdjust("floor", realNumber, -2);

            if (index === currentMonths.length) {
              //find last number
              const lastNumber = (
                totalCostAdded -
                firstNumber * (currentMonths.length - 1)
              ).toFixed(2);
              devLogConsole(firstNumber);
              devLogConsole(lastNumber);
              //string to number
              newCostHoursElement[key] = parseFloat(
                parseFloat(lastNumber).toFixed(2)
              );
              totalCostHours = (
                totalCostHours + parseFloat(lastNumber)
              ).toFixed(2);
            } else {
              newCostHoursElement[key] = totalCostAdded / currentMonths.length;
              totalCostHours =
                totalCostHours + totalCostAdded / currentMonths.length;
            }
          } else {
            if (index === currentMonths.length) {
              newCostHoursElement[key] = totalCostAdded;
              totalCostHours = totalCostHours + totalCostAdded;
            } else {
              newCostHoursElement[key] = 0;
            }
          }
        }
      }
    });
    if (getNameFromId() === "Avença") {
      newCostHoursElement.total = totalCostHours;
    } else {
      newCostHoursElement.total = totalCostAdded;
    }

    devLogConsole(newCostHoursElement);

    var newHoursElement = {};
    newHoursElement.description = "Horas";
    var totalHours = 0;

    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          var n = new Date(0, 0);
          n.setSeconds(+hoursAvenca * 60 * 60);
          const hoursStringArray = hoursAvenca.toString().split(".");
          var minutes = "0." + hoursStringArray[1];
          minutes = Math.ceil(parseFloat(minutes) * 60);
          minutes = minutes.toString();
          if (minutes.length === 1) {
            minutes = "0" + minutes;
          }
          newHoursElement[key] = hoursStringArray[0] + ":" + minutes;
          totalHours =
            totalHours +
            parseInt(hoursElement.total.split(":")[0]) +
            parseInt(hoursElement.total.split(":")[1]) / 60;
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              const hoursStringArray = totalHoras.toString().split(".");
              var minutes = "0." + hoursStringArray[1];
              minutes = Math.ceil(parseFloat(minutes) * 60);
              minutes = minutes.toString();
              if (minutes.length === 1) {
                minutes = "0" + minutes;
              }
              newHoursElement[key] = hoursStringArray[0] + ":" + minutes;
            } else {
              newHoursElement[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            if (index === currentMonths.length) {
              var n = new Date(0, 0);
              n.setSeconds(+totalHoursAdded[index - 1] * 60 * 60);
              const hoursStringArray = totalHoursAdded[index - 1]
                .toString()
                .split(".");
              var minutes = "0." + hoursStringArray[1];
              minutes = Math.ceil(parseFloat(minutes) * 60);
              minutes = minutes.toString();
              if (minutes.length === 1) {
                minutes = "0" + minutes;
              }
              const newHours = hoursStringArray[0] + ":" + minutes;
              newHoursElement[key] = newHours;
            } else {
              var hours = totalHoursAdded[index - 1].toString().split(".")[0];
              if (hours.length === 1) hours = "0" + hours;

              var minutes = Math.round(
                (parseFloat(
                  totalHoursAdded[index - 1].toFixed(2).split(".")[1]
                ) /
                  100) *
                  60
              );

              if (minutes.toString().length === 1) minutes = "0" + minutes;
              const newHours = hours + ":" + minutes;
              newHoursElement[key] = newHours;
            }
          } else {
            if (index === currentMonths.length) {
              const hoursStringArray = totalHoras.toString().split(".");
              var minutes = "0." + hoursStringArray[1];
              minutes = Math.ceil(parseFloat(minutes) * 60);
              minutes = minutes.toString();
              if (minutes.length === 1) {
                minutes = "0" + minutes;
              }
              newHoursElement[key] = hoursStringArray[0] + ":" + minutes;
            } else {
              newHoursElement[key] = 0;
            }
          }
        }
      }
    });

    if (getNameFromId() === "Avença") {
      const hoursStringArray = totalHoras.toString().split(".");
      var minutes = "0." + hoursStringArray[1];
      minutes = Math.ceil(parseFloat(minutes) * 60);
      minutes = minutes.toString();
      if (minutes.length === 1) {
        minutes = "0" + minutes;
      }
      newHoursElement.total = hoursStringArray[0] + ":" + minutes;
    } else {
      const hoursStringArray = totalHoras.toString().split(".");
      var minutes = "0." + hoursStringArray[1];
      minutes = Math.ceil(parseFloat(minutes) * 60);
      minutes = minutes.toString();
      if (minutes.length === 1) {
        minutes = "0" + minutes;
      }
      newHoursElement.total = hoursStringArray[0] + ":" + minutes;
    }

    var newMarginElement = {};
    newMarginElement.description = "Margem";
    var totalMargin = 0;

    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          newMarginElement[key] =
            newTotalIncomeElement[key] - newCostHoursElement[key];
          totalMargin = totalMargin + marginElement.total;
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              newMarginElement[key] =
                newTotalIncomeElement.total - newCostHoursElement.total;
              totalMargin = totalMargin + marginElement.total;
            } else {
              newMarginElement[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            newMarginElement[key] =
              newTotalIncomeElement[key] - newCostHoursElement[key];
            //newMarginElement.total = newMarginElement.total + newTotalIncomeElement[key]- newCostHoursElement[key]
          } else {
            if (index === currentMonths.length) {
              newMarginElement[key] =
                newTotalIncomeElement.total - newCostHoursElement.total;
              totalMargin = totalMargin + marginElement.total;
            } else {
              newMarginElement[key] = 0;
            }
          }
        }
      }
    });
    newMarginElement.total =
      newTotalIncomeElement.total - newCostHoursElement.total;

    var newPercMargin = {};
    newPercMargin.description = "% Margem";
    var totalPercIncome = 0;
    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          newPercMargin[key] =
            (newMarginElement[key] / newTotalFactureElement[key]) * 100;
          totalPercIncome =
            newMarginElement.total / newTotalFactureElement.total;
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              if (newTotalFactureElement[key] === 0) {
                newPercMargin[key] = 0;
              } else {
                newPercMargin[key] =
                  (newMarginElement[key] / newTotalFactureElement[key]) * 100;
              }
              totalPercIncome = totalPercIncome + percentMarginElement.total;
            } else {
              newPercMargin[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            if (newTotalFactureElement[key] === 0) {
              newPercMargin[key] = 0;
            } else {
              newPercMargin[key] =
                (newMarginElement[key] / newTotalFactureElement[key]) * 100;
            }
            //newMarginElement.total = newMarginElement.total + newTotalIncomeElement[key]- newCostHoursElement[key]
          } else {
            if (index === currentMonths.length) {
              if (newTotalFactureElement[key] === 0) {
                newPercMargin[key] = 0;
              } else {
                newPercMargin[key] =
                  (newMarginElement[key] / newTotalFactureElement[key]) * 100;
              }
              totalPercIncome = totalPercIncome + percentMarginElement.total;
            } else {
              newPercMargin[key] = 0;
            }
          }
        }
      }
    });
    if (newTotalFactureElement.total === 0) {
      newPercMargin.total = 0;
    } else {
      newPercMargin.total =
        (newMarginElement.total / newTotalFactureElement.total) * 100;
    }

    setMonthsArray(monthElement);

    totalFactureElementVerify.description = "Total a faturar";

    setFactureArrayStateVerify(factureArrayVerify);
    setTotalFactureVerify(totalFactureElementVerify);
    setCostArrayStateVerify(costArrayVerify);
    totalCostElementVerify.description = "Total Custos";
    setTotalCostVerify(totalCostElementVerify);

    setCostHours(newCostHoursElement);
    setHours(newHoursElement);

    incomeElementVerify.description = "Income";
    percentIncomeElementVerify.description = "";

    setIncomeVerify(incomeElementVerify);
    setPercIncomeVerify(percentIncomeElementVerify);

    marginElementVerify.description = "Margem";
    percentMarginElementVerify.description = "";

    setMarginVerify(marginElementVerify);
    setPercMarginVerify(percentMarginElementVerify);

    setMarginOk(marginOkElement);

    marginElement.description = "Margem";
    percentMarginElement.description = "";
    incomeElement.description = "Income";
    percentIncomeElement.description = "";
    totalFactureElement.description = "Total a Faturar";
    totalCostElement.description = "Total Custos";

    setPercMargin(newPercMargin);
    setMargin(newMarginElement);
    setPercIncome(newPercIncomeElement);
    setIncome(newTotalIncomeElement);
    setTotalCost(newTotalCostElement);
    setTotalFacture(newTotalFactureElement);
    setTableTitle(titleElement);
    var newResumeFact = [];
    var newTotalSummed = 0;
    var newIncomeSummed = 0;
    var newsubcontratosSummed = 0;
    Object.keys(newTotalFactureElement).map((key, index) => {
      if (index > 0) {
        newResumeFact.push({
          index: index,
          value: parseFloat(newTotalFactureElement[key]),
        });

        if (index != Object.keys(newTotalFactureElement).length - 1) {
          newTotalSummed =
            newTotalSummed + parseFloat(newTotalFactureElement[key]);
        }
      }
    });
    Object.keys(newTotalIncomeElement).map((key, index) => {
      if (index > 0) {
        if (index != Object.keys(newTotalIncomeElement).length - 1) {
          newIncomeSummed =
            newIncomeSummed + parseFloat(newTotalIncomeElement[key]);
        }
      }
    });
    Object.keys(newTotalCostElement).map((key, index) => {
      if (index > 0) {
        if (index != Object.keys(newTotalCostElement).length - 1) {
          newsubcontratosSummed =
            newsubcontratosSummed + parseFloat(newTotalCostElement[key]);
        }
      }
    });

    workplans[workplanIndex].faturacao = newTotalSummed;
    workplans[workplanIndex].subcontratos = newsubcontratosSummed; // substituir por newsubcontratosSummed
    workplans[workplanIndex].income = newIncomeSummed;
    workplans[workplanIndex].percIncome = parseFloat(
      percentIncomeElement.total.toFixed(5)
    );
    workplans[workplanIndex].resumoFinaceiro.resumeFactoration = newResumeFact;
    if (comeFromSave) {
      workplans[workplanIndex].resumoFinaceiro.resumeFactoration =
        workplanById.resumoFinaceiro.resumeFactoration;
    }

    /*
    devLogConsole(
      "newTotalSummed",
      newTotalSummed,
      "newTotalFactureElement.total",
      newTotalFactureElement.total
    );*/

    if (
      parseFloat(newTotalSummed).toFixed(2) !=
      parseFloat(newTotalFactureElement.total).toFixed(2)
    ) {
      workplans[workplanIndex].resumoFinaceiro.canSave = false;
    } else {
      workplans[workplanIndex].resumoFinaceiro.canSave = true;
    }
  };

  const handleSaveAndGoBack = () => {
    setCalculateGoBackFunction(true);
    updateItemFunction(0, true);
    dispatch(setWorkplanById({}));
    setLetShowButtons(false);
    setFilters([
      { type: "", value: "" },
      { type: "", value: "" },
      { type: "", value: "" },
      { type: "", value: "" },
    ]);
    setShowWorkPlan(false);
  };

  const handleGoBackwithoutSave = () => {
    workplans[workplanIndex].isChanged = false;
    goBack(true);
    setLetShowButtons(false);
    dispatch(setWorkplanById({}));
  };

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Plano Submetido!" status="success" />
      </div>
    );
  }

  function createLockBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div className="easypay-sub-header-3-warning">
              {t(`workplan.lock`)}
            </div>
          </div>
          <SimpleButton
            text={t(`workplan.close`)}
            variant={"liftworld-button-primary"}
            onClick={resetTimer}
          />
        </div>
      </div>
    );
  }

  function createBackAndSaveBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div className="easypay-sub-header-3-info-small">
              {t(`workplan.changedProposal`)}
            </div>
          </div>
          <div
            style={{ display: "inline-flex", alignSelf: "center", gap: "3rem" }}
          >
            <SimpleButton
              text={t(`workplan.save`)}
              variant={"liftworld-button-primary"}
              onClick={handleSaveAndGoBack}
            />
            <SimpleButton
              text={t(`workplan.close`)}
              variant={"liftworld-button-danger"}
              onClick={handleGoBackwithoutSave}
            />
          </div>
        </div>
      </div>
    );
  }

  function createBodyWaiting() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Por favor aguarde." status="waiting" />
      </div>
    );
  }

  function createBodySubmitError() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Erro ao submeter plano" status="error" />
      </div>
    );
  }

  function createBodySave() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Alterações guardadas" status="success" />
      </div>
    );
  }

  function createBodyError() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <ProvaDeVida text="Erro ao Submeter" status="error" />
          <div style={{ fontWeight: "bold" }}>{errorString}</div>
        </div>
      </div>
    );
  }

  function createBodyErrorUpdating() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <ProvaDeVida text="Erro ao guardar alterações!!" status="error" />
        </div>
      </div>
    );
  }

  function createBodyErrorFact() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <ProvaDeVida
            text="Erro ao guardar alterações! Valor de faturação diferente do total"
            status="error"
          />
        </div>
      </div>
    );
  }

  function createBodyProjectNumberError() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <ProvaDeVida text="Nº de projeto não pode ser vazio" status="error" />
        </div>
      </div>
    );
  }

  function goBack(close = false) {
    setFilters([
      { type: "", value: "" },
      { type: "", value: "" },
      { type: "", value: "" },
      { type: "", value: "" },
    ]);
    if (popupTimer.current) {
      clearTimeout(popupTimer.current);
    }
    const areDifferent = workplans[workplanIndex].isChanged;
    const toChangeValue = workplans[workplanIndex].resumoFinaceiro.toChange;

    if (areDifferent && !isModalOpen && isOrgGroupAdmin() && !close) {
      setShowModalToGoBack(true);
    } else {
      workplanByIdCopy.resumoFinaceiro.toChange = toChangeValue;
      workplanByIdCopy.lock = "";
      workplanByIdCopy.submitted = workplans[workplanIndex].submitted;
      workplans[workplanIndex] = workplanByIdCopy;
      delete workplans[workplanIndex].isChanged;
      dispatch(
        saveChangesWorkplan({ content: workplans[workplanIndex], show: false })
      );
      dispatch(setErrorUpdating(null));
      setLetShowButtons(false);
      setShowWorkPlan(false);
      setFilters([
        { type: "", value: "" },
        { type: "", value: "" },
        { type: "", value: "" },
        { type: "", value: "" },
      ]);
    }
    //window.location.reload(false);
  }

  function getIflow() {
    var missingParams = [];
    if (workplans[workplanIndex].clientDirectorProjeto.length === 0) {
      missingParams.push("CLIENT DIRETOR DO PROJETO");
    }
    if (workplans[workplanIndex].leadComercial === "") {
      missingParams.push("LEAD COMERCIAL");
    }
    if (workplans[workplanIndex].equipa.length === 0) {
      missingParams.push("EQUIPA");
    }
    if (workplans[workplanIndex].numClienteProjeto === 0) {
      missingParams.push("Nº CLIENTE PROJETO , NOME CLIENTE PROJETO");
    }
    if (workplans[workplanIndex].clientOwner === "") {
      missingParams.push("CLIENT OWNER");
    }
    if (workplans[workplanIndex].clientPartner === "") {
      missingParams.push("CLIENT PARTNER");
    }
    if (workplans[workplanIndex].nomeProposta === "") {
      missingParams.push("NOME DA PROPOSTA");
    }
    if (workplans[workplanIndex].tipoProjeto === "") {
      missingParams.push("TIPO DE PROJETO");
    }
    if (workplans[workplanIndex].probabilidade === 0) {
      missingParams.push("PROBABILIDADE");
    }
    if (workplans[workplanIndex].business === 0) {
      missingParams.push("BUSINESS");
    }
    if (workplans[workplanIndex].proposta === "") {
      missingParams.push("PROPOSTA");
    }
    if (
      workplans[workplanIndex].dataInicioProjeto === "" ||
      workplans[workplanIndex].dataInicioProjeto === null
    ) {
      missingParams.push("DATA INICIO DO PROJETO");
    }
    if (
      workplans[workplanIndex].dataFinalProjeto === "" ||
      workplans[workplanIndex].dataFinalProjeto === null
    ) {
      missingParams.push("DATA FINAL DO PROJETO");
    }

    var currentErrorString =
      "Falta preencher os seguintes campos obrigatórios: ";
    if (missingParams.length > 0) {
      for (var i = 0; i < missingParams.length; i++) {
        if (i != missingParams.length - 1) {
          currentErrorString = currentErrorString + missingParams[i] + " , ";
        } else {
          currentErrorString = currentErrorString + missingParams[i];
        }
      }
      setErrorString(currentErrorString);
      setIsModalErrorShowing(true);
      return;
    }
    setComeFromSave(false);
    dispatch(setIflowTriggerLoading(true));
    updateItemFunction(1);
  }

  const isOrgGroupAdmin = () => {
    if (
      user &&
      user.roles &&
      user.roles.some(
        (r) => r.label === "ADMIN" || r.label === "BUDGETMGROrgGroupAdmin"
      )
    ) {
      return true;
    } else return false;
  };

  function isButtonsVisible() {
    const shouldUnlockAndDispatch = checkAdminAndProposal(
      workplans[workplanIndex].estado
    );

    return shouldUnlockAndDispatch;
  }

  const showPopup = () => {
    setIsModalOpen(true);
    popupTimer.current = setTimeout(() => goBack(true), 1 * 60 * 1000);
  };

  const resetTimer = () => {
    setIsModalOpen(false);
    if (popupTimer.current) {
      clearTimeout(popupTimer.current);
    }
    popupTimer.current = setTimeout(showPopup, 6 * 60 * 1000);
  };

  useEffect(() => {
    return () => {
      if (popupTimer.current) {
        clearTimeout(popupTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      setFilters([
        { type: "", value: "" },
        { type: "", value: "" },
        { type: "", value: "" },
        { type: "", value: "" },
      ]);
      goBack(true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handlesubmitPlan = () => {
    getIflow();
    const flowId = budgetmgrConfiguration?.flowId || "337";
    const payload = {
      url: `/inicio_flow.jsp?flowid=${flowId}&attr_workplanId=${
        workplans[workplanIndex].id
      }&attr_Authorization=${oauth.getAccessToken()}&Authorization=${oauth.getAccessToken()}`,
    };
    setIsModalWaitingShowing(true);
    setProposalDisabled(true);
    setDidSubmit(true);
    dispatch(getIflowFormdata(payload));
    dispatch(setDocumentInfo({}));
  };

  return (
    <>
      {isLock ? (
        <>
          {<Spinner spinning={isWaiting} />}
          {isWaiting === false && workplans[workplanIndex] !== undefined && (
            <div
              style={{
                width: "100%",
                height: "100%",
                paddingLeft: "60px",
                paddingRight: "60px",
                paddingTop: "60px",
                paddingBottom: "60px",
                background: "white",
              }}
            >
              <div className="liftworld-header">PLANO DE TRABALHO</div>
              <Modal
                showModal={isModalOpen}
                bodyContent={createLockBody()}
              ></Modal>
              <Modal
                showModal={showModalToGoBack}
                bodyContent={createBackAndSaveBody()}
              ></Modal>
              <Modal
                className="easypay-modal"
                showModal={isModalShowing}
                setShowModal={setIsModalShowing}
                headerContent={"Submissão de Plano"}
                bodyContent={createBody()}
                key={uuid()}
              ></Modal>
              <Modal
                className="easypay-modal"
                showModal={isModalSaveShowing}
                setShowModal={setIsModalSaveShowing}
                headerContent={"Guardar alterações"}
                bodyContent={createBodySave()}
                key={uuid()}
              ></Modal>
              <Modal
                className="easypay-modal"
                showModal={isModalShowingErrorFact}
                setShowModal={setIsModalShowingErrorFact}
                headerContent={"Erro"}
                bodyContent={createBodyErrorFact()}
                key={uuid()}
              ></Modal>
              <Modal
                className="easypay-modal"
                showModal={isModalWaitingShowing}
                setShowModal={setIsModalWaitingShowing}
                headerContent={"Submissão de Plano"}
                bodyContent={createBodyWaiting()}
                key={uuid()}
              ></Modal>
              <Modal
                className="easypay-modal"
                showModal={isModalErrorShowing}
                setShowModal={setIsModalErrorShowing}
                headerContent={"Erro"}
                bodyContent={createBodyError()}
                key={uuid()}
              ></Modal>
              <Modal
                className="easypay-modal"
                showModal={isModalErrorUpdatingShowing}
                setShowModal={setIsModalErrorUpdatingShowing}
                headerContent={"Erro"}
                bodyContent={createBodyErrorUpdating()}
                key={uuid()}
              ></Modal>
              <Modal
                className="easypay-modal"
                showModal={isModalSubmitErrorShowing}
                setShowModal={setIsModalSubmitErrorShowing}
                headerContent={"Erro"}
                bodyContent={createBodySubmitError()}
                key={uuid()}
              ></Modal>
              <Modal
                className="easypay-modal"
                showModal={isModalProjectNumberErrorShowing}
                setShowModal={setIsModalProjectNumberErrorShowing}
                headerContent={"Erro"}
                bodyContent={createBodyProjectNumberError()}
                key={uuid()}
              ></Modal>
              <SimpleButton
                text={"Voltar"}
                variant={"easypay-button-link"}
                onClick={() => goBack()}
              />
              <div style={{ paddingBottom: "20px" }} />
              <div className="">
                <TabSeparatorWrapper
                  defaultActive={defaultActive}
                  resetTimer={resetTimer}
                >
                  <TabSeparator
                    text="Dados"
                    name="tab1"
                    variant={"liftworld-tab-wrapper"}
                  >
                    <InitalDataSection
                      items={
                        workplans[workplanIndex]
                          ? workplans[workplanIndex]
                          : workplans
                      }
                      workplanIndex={workplanIndex}
                      setProposalDisabled={setProposalDisabled}
                    />
                  </TabSeparator>
                  <TabSeparator
                    text="Plano de Trabalho"
                    name="tab2"
                    variant={"liftworld-tab-wrapper"}
                  >
                    <Item
                      list={
                        workplans[0]
                          ? workplans[workplanIndex]?.items
                          : workplans
                      }
                      workplanIndex={workplanIndex}
                    />
                    <div className="liftworld-item-bottom-button-wrapper">
                      <AddButton
                        text="Adicionar Nova Atividade Principal"
                        onClick={() => addItem()}
                      />
                    </div>
                  </TabSeparator>
                  <TabSeparator
                    text="Resumo financeiro"
                    name="tab3"
                    variant={"liftworld-tab-wrapper"}
                  >
                    <ProjectDescriptionSection
                      workplan={
                        workplans[workplanIndex]
                          ? workplans[workplanIndex]
                          : workplans
                      }
                      workplanIndex={workplanIndex}
                      comeFromSave={comeFromSave}
                      calculateGoBackFunction={calculateGoBackFunction}
                      totalOpt1CostValue={totalOpt1CostValue}
                      totalOpt2CostValue={totalOpt2CostValue}
                      totalOpt3CostValue={totalOpt3CostValue}
                      totalOptSelectedCostValue={totalOptSelectedCostValue}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      option1CostValue={option1CostValue}
                      setOption1CostValue={setOption1CostValue}
                      option1SaleValue={option1SaleValue}
                      setOption1SaleValue={setOption1SaleValue}
                      option1FinalMargin={option1FinalMargin}
                      setOption1FinalMargin={setOption1FinalMargin}
                      option2CostValue={option2CostValue}
                      setOption2CostValue={setOption2CostValue}
                      option2SaleValue={option2SaleValue}
                      setOption2SaleValue={setOption2SaleValue}
                      option2FinalMargin={option2FinalMargin}
                      setOption2FinalMargin={setOption2FinalMargin}
                      option3CostValue={option3CostValue}
                      setOption3CostValue={setOption3CostValue}
                      option3SaleValue={option3SaleValue}
                      setOption3SaleValue={setOption3SaleValue}
                      option3FinalMargin={option3FinalMargin}
                      setOption3FinalMargin={setOption3FinalMargin}
                      optionSelectedCostValue={optionSelectedCostValue}
                      setOptionSelectedCostValue={setOptionSelectedCostValue}
                      optionSelectedSaleValue={optionSelectedSaleValue}
                      setOptionSelectedSaleValue={setOptionSelectedSaleValue}
                      optionSelectedFinalMargin={optionSelectedFinalMargin}
                      setOptionSelectedFinalMargin={
                        setOptionSelectedFinalMargin
                      }
                      showOption2={showOption2}
                      setShowOption2={setShowOption2}
                      showOption3={showOption3}
                      setShowOption3={setShowOption3}
                      monthsArray={monthsArray}
                      setMonthsArray={setMonthsArray}
                      factureArrayState={factureArrayState}
                      setFactureArrayState={setFactureArrayState}
                      costArrayState={costArrayState}
                      setCostArrayState={setCostArrayState}
                      tableTitle={tableTitle}
                      setTableTitle={setTableTitle}
                      totalFacture={totalFacture}
                      setTotalFacture={setTotalFacture}
                      totalCost={totalCost}
                      setTotalCost={setTotalCost}
                      income={income}
                      setIncome={setIncome}
                      percIncome={percIncome}
                      setPercIncome={setPercIncome}
                      costHours={costHours}
                      setCostHours={setCostHours}
                      hours={hours}
                      setHours={setHours}
                      margin={margin}
                      setMargin={setMargin}
                      percMargin={percMargin}
                      setPercMargin={setPercMargin}
                      marginOk={marginOk}
                      setMarginOk={setMarginOk}
                      factureArrayStateVerify={factureArrayStateVerify}
                      setFactureArrayStateVerify={setFactureArrayStateVerify}
                      costArrayStateVerify={costArrayStateVerify}
                      setCostArrayStateVerify={setCostArrayStateVerify}
                      totalFactureVerify={totalFactureVerify}
                      setTotalFactureVerify={setTotalFactureVerify}
                      totalCostVerify={totalCostVerify}
                      setTotalCostVerify={setTotalCostVerify}
                      incomeVerify={incomeVerify}
                      setIncomeVerify={setIncomeVerify}
                      percIncomeVerify={percIncomeVerify}
                      setPercIncomeVerify={setPercIncomeVerify}
                      marginVerify={marginVerify}
                      setMarginVerify={setMarginVerify}
                      percMarginVerify={percMarginVerify}
                      setPercMarginVerify={setPercMarginVerify}
                      callRecalculate={callRecalculate}
                    />
                  </TabSeparator>
                  <TabSeparator
                    text="Proposta Financeira"
                    name="tab4"
                    variant={"liftworld-tab-wrapper"}
                    disabled={
                      workplans[workplanIndex].submitted === 1 ? false : true
                    }
                  >
                    <Proposal workplanIndex={workplanIndex} />
                  </TabSeparator>
                  <TabSeparator
                    text="Simulador"
                    name="tab6"
                    variant={"liftworld-tab-wrapper"}
                    disabled={false}
                  >
                    <Simulator
                      workplan={workplans[workplanIndex]}
                      workplanIndex={workplanIndex}
                    />
                  </TabSeparator>
                  <TabSeparator
                    text="Orçam. Global"
                    name="tab5"
                    variant={"liftworld-tab-wrapper"} 
                    disabled={
                      workplans[workplanIndex].submitted === 1 ? false : true
                    }
                  >
                    <BudgetDocumentPreviewPage
                      workId={workplans[workplanIndex].id}
                      type={"1"}
                    />{" "}
                  </TabSeparator>
                  <TabSeparator
                    text="Orçam. Parcial"
                    name="tab7"
                    variant={"liftworld-tab-wrapper"}
                    disabled={
                      workplans[workplanIndex].submitted === 1 ? false : true
                    }
                  >
                    <BudgetDocumentPreviewPageParcial
                      workId={workplans[workplanIndex].id}
                      type={"2"}
                    />{" "}
                  </TabSeparator>
                  <TabSeparator
                    text="Orçam. Res."
                    name="tab8"
                    variant={"liftworld-tab-wrapper"}
                    disabled={
                      workplans[workplanIndex].submitted === 1 ? false : true
                    }
                  >
                    <BudgetDocumentPreviewPageRed
                      workId={workplans[workplanIndex].id}
                      type={"3"}
                    />{" "}
                  </TabSeparator>
                </TabSeparatorWrapper>
                {
                  <div className="liftworld-workplan-bottom-button-wrapper">
                    {isButtonsVisible() && (
                      <>
                        <ProtectedComponent
                          permissions={[
                            { label: "app-budgetmgr-workplan-update" },
                          ]}
                        >
                          <SimpleButton
                            text={"Submeter Plano"}
                            variant={"liftworld-button-primary"}
                            onClick={handlesubmitPlan}
                          />
                        </ProtectedComponent>
                        <ProtectedComponent
                          permissions={[
                            { label: "app-budgetmgr-workplan-update" },
                          ]}
                        >
                          <SimpleButton
                            text={"Guardar alterações"}
                            variant={"liftworld-button-primary"}
                            onClick={() => {
                              updateItemFunction(0);
                              setComeFromSave(true);
                            }}
                          />
                        </ProtectedComponent>
                      </>
                    )}
                  </div>
                }
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            paddingLeft: "60px",
            paddingRight: "60px",
            paddingTop: "60px",
            paddingBottom: "60px",
            background: "white",
          }}
        >
          <div className="liftworld-header">PLANO DE TRABALHO</div>
          <SimpleButton
            text={"Voltar"}
            variant={"easypay-button-link"}
            onClick={() => {
              setFilters([
                { type: "", value: "" },
                { type: "", value: "" },
                { type: "", value: "" },
                { type: "", value: "" },
              ]);
              setShowWorkPlan(false);
            }}
          />
          <p>
            {" "}
            {t(`workplan.otheruser`)} {isLockUser}
          </p>
        </div>
      )}
    </>
  );
};
export default withNamespaces()(WorkPlanPage);
