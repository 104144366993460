export const TOGGLE_CHECK = "portalrh@TOGGLE_CHECK";
export const GET_CHECK = "portalrh@GET_CHECK";
export const SET_CHECK = "portalrh@SET_CHECK";
export const SET_BLOCK_CHECK = "portalrh@SET_BLOCK_CHECK";
export const REDIRECT_USER_TO = "portalrh@REDIRECT_USER_TO";
export const CLEAR_CHECK = "portal@CLEAR_CHECK";

export const EXPORT_TXT = "portalrh@EXPORT_TXT";
export const GET_GROUP_USERS = "portalrh@GET_GROUP_USERS";
export const GET_REGISTO_FALTAS = "portalrh@GET_REGISTO_FALTAS";
export const SET_REGISTO_FALTAS = "portalrh@SET_REGISTO_FALTAS";
export const SET_SUCCESS_ADD = "portalrh@SET_SUCCESS_ADD";
export const SET_GROUP_USERS = "portalrh@SET_GROUP_USERS";
export const GET_TIPO_AUSENCIA = "portal@GET_TIPO_AUSENCIA";
export const SET_TIPO_AUSENCIA = "portal@SET_TIPO_AUSENCIA";
export const GET_TIPO_AUSENCIA_IDONIC = "portal@GET_TIPO_AUSENCIA_IDONIC";
export const SET_TIPO_AUSENCIA_IDONIC = "portal@SET_TIPO_AUSENCIA_IDONIC";

export const GET_DOCUMENTS = "portalrh@GET_DOCUMENTS";
export const GET_DOCUMENTS_USERNAME = "portalrh@GET_DOCUMENTS_USERNAME";
export const SET_DOCUMENTS = "portalrh@SET_DOCUMENTS";
export const SAVE_DOCUMENTLIST = "portal@SAVE_DOCUMENTLIST";
export const SAVE_DOCUMENTLIST_USERNAME = "portal@SAVE_DOCUMENTLIST_USERNAME";
export const DELETE_DOCUMENT = "portal@DELETE_DOCUMENT";
export const CLEAR_DOCUMENTS = "portal@CLEAR_DOCUMENTS";
export const GET_DOCUMENT = "portal@GET_DOCUMENT";
export const CLEAR_DOCUMENT = "portal@CLEAR_DOCUMENT";
export const SET_DOCUMENT = "portal@SET_DOCUMENT";
export const SUCCESS_DELETE = "portal@SUCCESS_DELETE";

export const GET_OPERATIONS = "portalrh@GET_OPERATIONS";
export const SET_OPERATIONS = "portalrh@SET_OPERATIONS";

export const GET_PREVIEW = "portalrh@GET_PREVIEW";
export const SET_PREVIEW = "portalrh@SET_PREVIEW";

export const UPDATE_DOCUMENT = "portal@UPDATE_DOCUMENT";
export const GET_COLLABORATORS = "portal@GET_COLLABORATORS";

export const GET_SCHEDULER = "portal@GET_SCHEDULER";
export const SET_SCHEDULER = "portal@SET_SCHEDULER";

export const SET_COLLABORATORS = "portal@SET_COLLABORATORS";

export const GET_DOCUMENTS_ADMIN = "portalrh@GET_DOCUMENTS_ADMIN";
export const SET_DOCUMENTS_ADMIN = "portalrh@SET_DOCUMENTS_ADMIN";
export const FIND_DOCUMENTS_BY_FILTERS = "portal@FIND_DOCUMENTS_BY_FILTERS";

export const GET_REQUESTS_BY_USERNAME = "portalrh@GET_REQUESTS_BY_USERNAME";
export const SET_REQUESTS_BY_USERNAME = "portalrh@SET_REQUESTS_BY_USERNAME";

export const GET_REQUESTS_BY_USERNAME_TYPE =
  "portalrh@GET_REQUESTS_BY_USERNAME_TYPE";
export const SET_REQUESTS_BY_USERNAME_TYPE =
  "portalrh@SET_REQUESTS_BY_USERNAME_TYPE";

export const CHECK_UPDATE_BY_USERNAME = "portal@CHECK_UPDATE_BY_USERNAME";

export const SET_GENERIC_REQUEST_STATE = "portal@SET_GENERIC_REQUEST_STATE";
export const SET_GENERIC_AUTHORIZATION_STATE =
  "portal@SET_GENERIC_AUTHORIZATION_STATE";

export const GET_USER_PAYDATA = "portal@GET_USER_PAYDATA";
export const SET_USER_PAYDATA = "portal@SET_USER_PAYDATA";
export const GET_USER_SYNCHRONIZATION_LOG =
  "portal@GET_USER_SYNCHRONIZATION_LOG";
export const SET_USER_SYNCHRONIZATION_LOG =
  "portal@SET_USER_SYNCHRONIZATION_LOG";

export const GET_OBJECTIVES = "portal@GET_OBJECTIVES";
export const SET_OBJECTIVES = "portal@SET_OBJECTIVES";

export const GET_OBJECTIVE_BY_ID = "portal@GET_OBJECTIVE_BY_ID";
export const SET_OBJECTIVE_BY_ID = "portal@SET_OBJECTIVE_BY_ID";

export const GET_EVALUATION_SYSTEMS = "portal@GET_EVALUATION_SYSTEMS";
export const SET_EVALUATION_SYSTEMS = "portal@SET_EVALUATION_SYSTEMS";

export const GET_EVALUATION_PERIODS = "portal@GET_EVALUATION_PERIODS";
export const SET_EVALUATION_PERIODS = "portal@SET_EVALUATION_PERIODS";

export const GET_USER_INFORMATIONS = "portalrh@GET_USER_INFORMATIONS";
export const SET_USER_INFORMATIONS = "portalrh@SET_USER_INFORMATIONS";

export const UPDATE_OPERATION_STATES = "portalrh@UPDATE_OPERATION_STATES";

export const GET_DOCS_DISCLAIMERS = "portalrh@GET_DOCS_DISCLAIMERS";
export const SET_DOCS_DISCLAIMERS = "portalrh@SET_DOCS_DISCLAIMERS";

export const UPDATE_DOCS_DISCLAIMER = "portalrh@UPDATE_DOCS_DISCLAIMER";

export const STOP_EDITING_DOCS_DISCLAIMERS =
  "portalrh@STOP_EDITING_DOCS_DISCLAIMERS";

export const UPLOAD_SCHEDULE = "portalrh@UPLOAD_SCHEDULE";
export const STOP_UPLOAD_SCHEDULE = "portalrh@STOP_UPLOAD_SCHEDULE";

export const UPLOAD_ATTENDANCE = "portalrh@UPLOAD_ATTENDANCE";
export const STOP_UPLOAD_ATTENDANCE = "portalrh@STOP_UPLOAD_ATTENDANCE";

export const GET_ENTITIES = "portalrh@GET_ENTITIES";
export const SET_ENTITIES = "portalrh@SET_ENTITIES";

export const GET_ENTITIES_ATTENDANCE = "portalrh@GET_ENTITIES_ATTENDANCE";
export const SET_ENTITIES_ATTENDANCE = "portalrh@SET_ENTITIES_ATTENDANCE";

export const GET_LOGS = "portalrh@GET_LOGS";
export const SET_LOGS = "portalrh@SET_LOGS";

export const GET_ATTENDANCE_LOGS = "portalrh@GET_ATTENDANCE_LOGS";
export const SET_ATTENDANCE_LOGS = "portalrh@SET_ATTENDANCE_LOGS";

export const GET_EXPORT_LOGS = "portalrh@GET_EXPORT_LOGS";
export const SET_EXPORT_LOGS = "portalrh@SET_EXPORT_LOGS";

export const GET_EXPORT_ABSENCE_LOGS = "portalrh@EXPORT_ABSENCE_LOGS";
export const SET_EXPORT_ABSENCE_LOGS = "portalrh@SET_EXPORT_ABSENCE_LOGS";

export const GET_EXPORT_ABSENCE_DATA = "portalrh@GET_EXPORT_ABSENCE_DATA";
export const SET_EXPORT_ABSENCE_DATA = "portalrh@SET_EXPORT_ABSENCE_DATA";
export const SET_LOADING_REQUESTS = "portalrh@SET_LOADING_REQUESTS";

export const GET_TRABALHO_REMOTO = "portalrh@GET_TRABALHO_REMOTO";
export const GET_ALL_TRABALHO_REMOTO = "portalrh@GET_ALL_TRABALHO_REMOTO";
export const POST_TRABALHO_REMOTO = "portalrh@POST_TRABALHO_REMOTO";
export const POST_TRABALHO_REMOTO_CONFIRM =
  "portalrh@POST_TRABALHO_REMOTO_CONFIRM";
export const SET_TRABALHO_REMOTO = "portalrh@SET_TRABALHO_REMOTO";
export const SET_INFO_SALDO_TRABALHO_REMOTO =
  "portalrh@SET_INFO_SALDO_TRABALHO_REMOTO";
export const SET_ERROR_TRABALHO_REMOTO = "portalrh@SET_ERROR_TRABALHO_REMOTO";
export const SET_ERROR_TRABALHO_REMOTO_CONFIRM =
  "portalrh@SET_ERROR_TRABALHO_REMOTO_CONFIRM";
export const SET_REMOVE_DAYS_TRABALHO_REMOTO =
  "portalrh@SET_REMOVE_DAYS_TRABALHO_REMOTO";
export const GET_INFORMATION_PROCESS_REMOTE_WORK =
  "portalrh@GET_INFORMATION_PROCESS_REMOTE_WORK";
export const SET_INFORMATION_PROCESS_REMOTE_WORK =
  "portalrh@SET_INFORMATION_PROCESS_REMOTE_WORK";
export const SET_INFORMATION_PROCESS_MARCACAO_FERIAS =
  "portalrh@SET_INFORMATION_PROCESS_MARCACAO_FERIAS";
export const APPROVE_DELETE_REMOVE_DAY_REMOTE_WORK =
  "portalrh@APPROVE_DELETE_REMOVE_DAY_REMOTE_WORK";
export const GET_GRUPOS_TRABALHO_REMOTO = "portalrh@GET_GRUPOS_TRABALHO_REMOTO";
export const SET_GRUPOS_TRABALHO_REMOTO = "portalrh@SET_GRUPOS_TRABALHO_REMOTO";
export const GET_INFO_SALDO_TRABALHO_REMOTO =
  "portalrh@GET_INFO_SALDO_TRABALHO_REMOTO";
export const SET_APPROVE_DELETE_REMOVE_DAY_REMOTE_WORK =
  "portalrh@SET_APPROVE_DELETE_REMOVE_DAY_REMOTE_WORK";

export const GET_EXPORTVALS = "portal@GET_EXPORTVALS";
export const SET_EXPORTVALS = "portal@SET_EXPORTVALS";

export const GET_EXPORTED_TABLE = "portal@GET_EXPORTED_TABLE";

export const GET_MARCACAO_FERIAS = "portal@GET_MARCACAO_FERIAS";
export const SET_MARCACAO_FERIAS = "portal@SET_MARCACAO_FERIAS";
export const SET_MARCACAO_FERIAS_CALENDARIO =
  "portal@SET_MARCACAO_FERIAS_CALENDARIO";
export const SET_SIMULADOR_FERIAS = "simulador@SET_SIMULADOR_FERIAS";
export const GET_ALL_MARCACAO_FERIAS = "portalrh@GET_ALL_MARCACAO_FERIAS";
export const POST_MARCACAO_FERIAS = "portalrh@POST_MARCACAO_FERIAS";
export const SET_ERROR_MARCACAO_FERIAS = "portalrh@SET_ERROR_MARCACAO_FERIAS";
export const POST_MARCACAO_FERIAS_CONFIRM =
  "portalrh@POST_MARCACAO_FERIAS_CONFIRM";
export const SET_ERROR_MARCACAO_FERIAS_CONFIRM =
  "portalrh@SET_ERROR_MARCACAO_FERIAS_CONFIRM";
export const GET_INFORMATION_PROCESS_MARCACAO_FERIAS =
  "portalrh@GET_INFORMATION_PROCESS_MARCACAO_FERIAS";
export const APPROVE_DELETE_REMOVE_MARCACAO_FERIAS =
  "portalrh@APPROVE_DELETE_REMOVE_MARCACAO_FERIAS";
export const SET_APPROVE_DELETE_REMOVE_DAY_MARCACAO_FERIAS =
  "portalrh@SET_APPROVE_DELETE_REMOVE_DAY_MARCACAO_FERIAS";
export const POST_REMOVE_DAYS_TRABALHO_REMOTO =
  "portalrh@POST_REMOVE_DAYS_TRABALHO_REMOTO";
export const GET_REQUESTS_MY_TASKS_WIDGET =
  "portalrh@GET_REQUESTS_MY_TASKS_WIDGET";
export const SET_REQUESTS_MY_TASKS_WIDGET =
  "portalrh@SET_REQUESTS_MY_TASKS_WIDGET";
export const GET_REQUESTS_MY_TASKS_WIDGET_PIDs =
  "portalrh@GET_REQUESTS_MY_TASKS_WIDGET_PIDS";
export const SET_REQUESTS_MY_TASKS_WIDGET_PIDs =
  "portalrh@SET_REQUESTS_MY_TASKS_WIDGET_PIDS";

export const SET_FLOW_ID_MY_TASKS_REQUEST =
  "portalrh@SET_FLOW_ID_MY_TASKS_REQUEST";
export const CANCEL_DAY_OFF_REQUEST = "portalrh@CANCEL_DAY_OFF_REQUEST";
export const GET_CONSUMO_SALDO = "portalrh@GET_CONSUMO_SALDO";
export const SET_CONSUMO_SALDO = "portalrh@SET_CONSUMO_SALDO";

export const GET_INFORMATION_PROCESS_AUTHORIZATION =
  "portalrh@GET_INFORMATION_PROCESS_AUTHORIZATION";
export const SET_INFORMATION_PROCESS_AUTHORIZATION =
  "portalrh@SET_INFORMATION_PROCESS_AUTHORIZATION";

export const GET_HORARIO_OPTIONS = "portalrh@GET_HORARIO_OPTIONS";
export const SET_HORARIO_OPTIONS = "portalrh@SET_HORARIO_OPTIONS";

export const POST_HORARIO_OPTIONS = "portalrh@POST_HORARIO_OPTIONS";
export const POST_HORARIO_OPTIONS_MESSAGE = "portalrh@POST_HORARIO_OPTIONS_MESSAGE";

export const toggleCheck = (t) => {
  return { type: TOGGLE_CHECK, t };
};
export const getCheck = () => {
  return { type: GET_CHECK };
};
export const setCheck = (data) => {
  return { type: SET_CHECK, data };
};

export const setblockCheckIn = (data) => {
  return { type: SET_BLOCK_CHECK, data };
};

export const redirectUserTo = (path) => {
  return { type: REDIRECT_USER_TO, path };
};
export const clearCheck = () => {
  return { type: CLEAR_CHECK };
};

export const getDocuments = () => {
  return { type: GET_DOCUMENTS };
};
export const getDocumentsbyUsername = (username) => {
  return { type: GET_DOCUMENTS_USERNAME, username };
};
export const setDocuments = (documents) => {
  return { type: SET_DOCUMENTS, documents };
};
export const saveDocumentList = (
  docList,
  uploadEmMassa,
  isForAll,
  notificationOption
) => {
  return {
    type: SAVE_DOCUMENTLIST,
    docList,
    uploadEmMassa,
    isForAll,
    notificationOption,
  };
};

export const saveDocumentListWithUsername = (
  docList,
  uploadEmMassa,
  username,
  isForAll
) => {
  return {
    type: SAVE_DOCUMENTLIST_USERNAME,
    docList,
    uploadEmMassa,
    username,
    isForAll,
  };
};

export const deleteDocument = (docId) => {
  return { type: DELETE_DOCUMENT, docId };
};
export const clearDocuments = () => {
  return { type: CLEAR_DOCUMENTS };
};
export const setSuccessDelete = () => {
  return { type: SUCCESS_DELETE };
};

export const getDocumentBinary = (id) => {
  return { type: GET_DOCUMENT, id };
};
export const setDocument = (document) => {
  return { type: SET_DOCUMENT, document };
};
export const clearDocument = () => {
  return { type: CLEAR_DOCUMENT };
};

export const getOperations = (t) => {
  return { type: GET_OPERATIONS, t };
};
export const setOperations = (operations) => {
  return { type: SET_OPERATIONS, operations };
};

export const getPreviewBinary = (id) => {
  return { type: GET_PREVIEW, id };
};
export const setPreviewBinary = (preview) => {
  return { type: SET_PREVIEW, preview };
};

export const updateDocument = (document) => {
  return { type: UPDATE_DOCUMENT, document };
};
export const getCollaborators = () => {
  return { type: GET_COLLABORATORS };
};
export const getScheduler = (payload) => {
  return { type: GET_SCHEDULER, payload };
};

export const getHorarioOptions = (entidade) => {
  return { type: GET_HORARIO_OPTIONS, entidade };
};

export const setHorarioOptions = (horarioOptions) => {
  return { type: SET_HORARIO_OPTIONS, horarioOptions };
};

export const postHorarioOptions = (payload) => {
  return { type: POST_HORARIO_OPTIONS, payload };
}

export const postHorarioOptionsMessage = (message) => {
  return { type: POST_HORARIO_OPTIONS_MESSAGE, message };
}

export const getExportVals = (payload) => {
  return { type: GET_EXPORTVALS, payload };
};

export const getExportedTable = (payload) => {
  return { type: GET_EXPORTED_TABLE, payload };
};

export const setExportVals = (message) => {
  return { type: SET_EXPORTVALS, message };
};

export const setCollaborators = (users) => {
  return { type: SET_COLLABORATORS, users };
};

export const getTrabalhoRemoto = (payload) => {
  return { type: GET_TRABALHO_REMOTO, payload };
};

export const getInfoSaldoTrabalhoRemoto = (payload) => {
  return { type: GET_INFO_SALDO_TRABALHO_REMOTO, payload };
};

export const getGruposTrabalhoRemoto = (payload) => {
  return { type: GET_GRUPOS_TRABALHO_REMOTO, payload };
};

export const getAllTrabalhoRemoto = (payload) => {
  return { type: GET_ALL_TRABALHO_REMOTO, payload };
};

export const postTrabalhoRemoto = (payload) => {
  return { type: POST_TRABALHO_REMOTO, payload };
};

export const postTrabalhoRemotoConfirm = (payload) => {
  return { type: POST_TRABALHO_REMOTO_CONFIRM, payload };
};

export const postRemoveDaysTrabalhoRemoto = (payload) => {
  return { type: POST_REMOVE_DAYS_TRABALHO_REMOTO, payload };
};

export const setTrabalhoRemoto = (trabalhoRemoto) => {
  return { type: SET_TRABALHO_REMOTO, trabalhoRemoto };
};

export const setTrabalhoRemotoInfoSaldo = (trabalhoRemoto) => {
  return { type: SET_INFO_SALDO_TRABALHO_REMOTO, trabalhoRemoto };
};

export const setGruposTrabalhoRemoto = (grupos) => {
  return { type: SET_GRUPOS_TRABALHO_REMOTO, grupos };
};

export const setErrorPostTrabalhoRemoto = (msg) => {
  return { type: SET_ERROR_TRABALHO_REMOTO, msg };
};

export const setErrorPostTrabalhoRemotoConfirm = (msg) => {
  return { type: SET_ERROR_TRABALHO_REMOTO_CONFIRM, msg };
};

export const setRemoveDaysTrabalhoRemoto = (msg) => {
  return { type: SET_REMOVE_DAYS_TRABALHO_REMOTO, msg };
};

export const setScheduler = (scheduler) => {
  return { type: SET_SCHEDULER, scheduler };
};

export const getDocumentsAdmin = () => {
  return { type: GET_DOCUMENTS_ADMIN };
};
export const setDocumentsAdmin = (documents) => {
  return { type: SET_DOCUMENTS_ADMIN, documents };
};
export const findDocumentsByFilters = (filters) => {
  return { type: FIND_DOCUMENTS_BY_FILTERS, filters };
};

export const getRequestsByUsername = (username, procGeneric) => {
  return { type: GET_REQUESTS_BY_USERNAME, username, procGeneric };
};

export const getRequestsByUsernameWithType = (username, from, searchFilter) => {
  return { type: GET_REQUESTS_BY_USERNAME_TYPE, username, from, searchFilter };
};

export const setRequestsByUsername = (requests) => {
  return { type: SET_REQUESTS_BY_USERNAME, requests };
};

export const setRequestsByUsernameWithType = (requests) => {
  return { type: SET_REQUESTS_BY_USERNAME_TYPE, requests };
};

export const checkUpdateByUsername = (username) => {
  return { type: CHECK_UPDATE_BY_USERNAME, username };
};

export const getUserPayData = (funcionarioNum) => {
  return { type: GET_USER_PAYDATA, funcionarioNum };
};

export const setUserPayData = (userPayData) => {
  return { type: SET_USER_PAYDATA, userPayData };
};

export const getUserInformations = (username) => {
  return { type: GET_USER_INFORMATIONS, username };
};

export const setUserInformations = (userInformations) => {
  return { type: SET_USER_INFORMATIONS, userInformations };
};
export const setGenericRequestState = (genericRequestState) => {
  return { type: SET_GENERIC_REQUEST_STATE, genericRequestState };
};
export const setGenericAuthorizationState = (genericAuthorizationState) => {
  return { type: SET_GENERIC_AUTHORIZATION_STATE, genericAuthorizationState };
};

export const setLoadingRequests = () => {
  return { type: SET_LOADING_REQUESTS };
};

export const getUserSynchronizationLog = () => {
  return { type: GET_USER_SYNCHRONIZATION_LOG };
};
export const setUserSynchronizationLog = (userSynchronizationLog) => {
  return { type: SET_USER_SYNCHRONIZATION_LOG, userSynchronizationLog };
};

export const getObjectives = () => {
  return { type: GET_OBJECTIVES };
};
export const setObjectives = (objectives) => {
  return { type: SET_OBJECTIVES, objectives };
};

export const getObjectiveByID = (obj_id) => {
  return { type: GET_OBJECTIVE_BY_ID, obj_id };
};
export const setObjectiveByID = (objective) => {
  return { type: SET_OBJECTIVE_BY_ID, objective };
};

export const getEvaluationSystems = () => {
  return { type: GET_EVALUATION_SYSTEMS };
};
export const setEvaluationSystems = (evaluationSystems) => {
  return { type: SET_EVALUATION_SYSTEMS, evaluationSystems };
};

export const getEvaluationPeriods = (sis_id) => {
  return { type: GET_EVALUATION_PERIODS, sis_id };
};
export const setEvaluationPeriods = (evaluationPeriods) => {
  return { type: SET_EVALUATION_PERIODS, evaluationPeriods };
};

export const updateOperationStates = (operations) => {
  return { type: UPDATE_OPERATION_STATES, operations };
};

export const getDocsDisclaimers = () => {
  return { type: GET_DOCS_DISCLAIMERS };
};
export const setDocsDisclaimers = (docsDisclaimers) => {
  return { type: SET_DOCS_DISCLAIMERS, docsDisclaimers };
};

export const updateDocsDisclaimer = (docsDisclaimer) => {
  return { type: UPDATE_DOCS_DISCLAIMER, docsDisclaimer };
};

export const stopEditingDocsDisclaimers = () => {
  return { type: STOP_EDITING_DOCS_DISCLAIMERS };
};

export const uploadSchedule = (uploadData, selectedEntityId) => {
  return { type: UPLOAD_SCHEDULE, uploadData, selectedEntityId };
};
export const stopUploadSchedule = () => {
  return { type: STOP_UPLOAD_SCHEDULE };
};

export const uploadAttendance = (uploadData, selectedEntityId) => {
  return { type: UPLOAD_ATTENDANCE, uploadData, selectedEntityId };
};
export const stopUploadAttendance = () => {
  return { type: STOP_UPLOAD_ATTENDANCE };
};

export const getEntities = () => {
  return { type: GET_ENTITIES };
};
export const setEntities = (entities) => {
  return { type: SET_ENTITIES, entities };
};

export const getEntitiesAttendance = () => {
  return { type: GET_ENTITIES_ATTENDANCE };
};
export const setEntitiesAttendance = (entities) => {
  return { type: SET_ENTITIES_ATTENDANCE, entities };
};

export const getLogs = () => {
  return { type: GET_LOGS };
};
export const setLogs = (logs) => {
  return { type: SET_LOGS, logs };
};

export const getAttendanceLogs = () => {
  return { type: GET_ATTENDANCE_LOGS };
};
export const setAttendanceLogs = (logs) => {
  return { type: SET_ATTENDANCE_LOGS, logs };
};

export const getExportLogs = (logs) => {
  return { type: GET_EXPORT_LOGS, logs };
};
export const setExportLogs = (base64) => {
  return { type: SET_EXPORT_LOGS, base64 };
};

export const getExportAbsenceLogs = () => {
  return { type: GET_EXPORT_ABSENCE_LOGS };
};
export const setExportAbsenceLogs = (absenceLogs) => {
  return { type: SET_EXPORT_ABSENCE_LOGS, absenceLogs };
};

export const getExportAbsenceData = () => {
  return { type: GET_EXPORT_ABSENCE_DATA };
};
export const setExportAbsenceData = (absenceData) => {
  return { type: SET_EXPORT_ABSENCE_DATA, absenceData };
};

export const exportTxt = (payload) => {
  return { type: EXPORT_TXT, payload };
};

export const getRegistoFaltas = (payload) => {
  return { type: GET_REGISTO_FALTAS, payload };
};

export const setRegistoFaltas = (registoFaltas) => {
  return { type: SET_REGISTO_FALTAS, registoFaltas };
};

export const successAdded = (boolean) => {
  return { type: SET_SUCCESS_ADD, boolean };
};

export const getGroupUsers = (typeData) => {
  return { type: GET_GROUP_USERS, typeData };
};

export const setGroupUsers = (groupUsers) => {
  return { type: SET_GROUP_USERS, groupUsers };
};

export const getTipoAusencias = () => {
  return { type: GET_TIPO_AUSENCIA };
};
export const setTipoAusencias = (tipoAusencias) => {
  return { type: SET_TIPO_AUSENCIA, tipoAusencias };
};

export const getTipoAusenciasIDONIC = () => {
  return { type: GET_TIPO_AUSENCIA_IDONIC };
};
export const setTipoAusenciasIDONIC = (tipoAusenciasIDONIC) => {
  return { type: SET_TIPO_AUSENCIA_IDONIC, tipoAusenciasIDONIC };
};

export const getInformationProcessRemoteWork = (payload) => {
  return { type: GET_INFORMATION_PROCESS_REMOTE_WORK, payload };
};

export const approveDeleteRemoveDayRemoteWork = (payload) => {
  return { type: APPROVE_DELETE_REMOVE_DAY_REMOTE_WORK, payload };
};

export const setInformationProcessRemoteWork = (info) => {
  return { type: SET_INFORMATION_PROCESS_REMOTE_WORK, info };
};

export const setInformationProcessMarcacaoFerias = (info) => {
  return { type: SET_INFORMATION_PROCESS_MARCACAO_FERIAS, info };
};

export const getInformationProcessMarcacaoFerias = (payload) => {
  return { type: GET_INFORMATION_PROCESS_MARCACAO_FERIAS, payload };
};
export const getInformationProcessAuthorization = (payload) => {
  return { type: GET_INFORMATION_PROCESS_AUTHORIZATION, payload };
};
export const setInformationProcessAuthorization = (info) => {
  return { type: SET_INFORMATION_PROCESS_AUTHORIZATION, info };
};

export const approveDeleteRemoveMarcacaoFerias = (payload) => {
  return { type: APPROVE_DELETE_REMOVE_MARCACAO_FERIAS, payload };
};

export const setapproveDeleteRemoveMarcacaoFerias = (info) => {
  return { type: SET_APPROVE_DELETE_REMOVE_DAY_MARCACAO_FERIAS, info };
};

export const setapproveDeleteRemoveDayRemoteWork = (info) => {
  return { type: SET_APPROVE_DELETE_REMOVE_DAY_REMOTE_WORK, info };
};

export const getMarcacaoFerias = (payload, simulador) => {
  return { type: GET_MARCACAO_FERIAS, payload, simulador };
};

export const setMarcacaoFerias = (marcacaoFerias) => {
  return { type: SET_MARCACAO_FERIAS, marcacaoFerias };
};
export const setMarcacaoFeriasCalendario = (calendario) => {
  return { type: SET_MARCACAO_FERIAS_CALENDARIO, calendario };
};

export const setSimuladorFerias = (simuladorFerias) => {
  return { type: SET_SIMULADOR_FERIAS, simuladorFerias };
};

export const getAllMarcacaoFerias = (payload) => {
  return { type: GET_ALL_MARCACAO_FERIAS, payload };
};

export const postMarcacaoFerias = (payload) => {
  return { type: POST_MARCACAO_FERIAS, payload };
};

export const cancelDayOffRequest = (payload) => {
  return { type: CANCEL_DAY_OFF_REQUEST, payload };
};

export const setErrorPostMarcacaoFerias = (msg) => {
  return { type: SET_ERROR_MARCACAO_FERIAS, msg };
};

export const postMarcacaoFeriasConfirm = (payload) => {
  return { type: POST_MARCACAO_FERIAS_CONFIRM, payload };
};
export const setErrorPostMarcacaoFeriasConfirm = (msg) => {
  return { type: SET_ERROR_MARCACAO_FERIAS_CONFIRM, msg };
};

export const getRequestsMyTasksWidget = (payload) => {
  return { type: GET_REQUESTS_MY_TASKS_WIDGET, payload };
};

export const setRequestsMyTasksWidget = (requests) => {
  return { type: SET_REQUESTS_MY_TASKS_WIDGET, requests };
};

export const getRequestsMyTasksWidgetPIDs = (usernamePortalRh) => {
  return { type: GET_REQUESTS_MY_TASKS_WIDGET_PIDs, usernamePortalRh };
};

export const setRequestsMyTasksWidgetPIDs = (pid) => {
  return { type: SET_REQUESTS_MY_TASKS_WIDGET_PIDs, pid };
};

export const setFlowIdMyTasksRequest = (flowId) => {
  return { type: SET_FLOW_ID_MY_TASKS_REQUEST, flowId };
};

export const getConsumoSaldo = () => {
  return { type: GET_CONSUMO_SALDO };
};
export const setConsumoSaldo = (consumoSaldo) => {
  return { type: SET_CONSUMO_SALDO, consumoSaldo };
};
