import React, { useState, useEffect } from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import RhTableList from "./RhTableList";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getTaxData,
  getTaxDataDropdown,
  updateTaxData,
  createTaxData,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import { DateTime } from "luxon";
import DateTimePicker from "react-datetime-picker";
import ButtonsUpdate from "./ButtonsUpdate";
import ButtonsCreate from "./ButtonsCreate";
import ButtonsCreateUpdate from "./ButtonsCreateUpdate";
import UploadDocument from "../UploadDocuments";

const RhTaxData = ({ rowCollaborator, t }) => {
  const [numbHolders, setNumbHolders] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [rowClicked, setRowClicked] = useState(false);
  const [numbDependents, setNumbDependents] = useState("");
  const [numbDisabledDep, setNumbDisabledDep] = useState("");
  const [taxZoneList, setTaxZoneList] = useState([]);
  const [taxZoneSelected, setTaxZonaSelected] = useState("");
  const [maritalSelected, setMaritalSelected] = useState("");
  const [taxSelected, setTaxSelected] = useState("");
  const [checkRetiree, setCheckRetiree] = useState(false);
  const [checkDisabledSpouse, setCheckdisabledSpouse] = useState(false);
  const [checkDisabledHolder, setCheckDisabledHolder] = useState(false);
  const [checkAwaitsRetire, setCheckAwaitsRetire] = useState(false);
  const [checkActive, setCheckActive] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [taxDataPage, setTaxDataPage] = useState(false);
  const [lepNum, setLepNum] = useState("");
  const [nifEmpCode, setFifEmpCode] = useState("");
  const [newButtonClicked, setNewButtonClicked] = useState(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
  const [flatTaxValue, setFlatTaxValue] = useState("");
  const [disabledHolderValue, setDisabledHolderValue] = useState("");
  const [validated, setValidated] = useState(false);
  const [isClickedBtnUpdate, setIsClickedBtnUpdate] = useState(false);
  const [effectiveDateError, setEffectiveDateError] = useState(false);
  const [taxZoneSelectedError, setTaxZoneSelectedError] = useState(false);
  const [taxSelectedError, setTaxSelectedError] = useState(false);
  const [maritalStatusError, setMaritalStatusError] = useState(false);
  const [validatedDropdowns, setValidatedDropdowns] = useState(false);
  const [isNew, setIsNew] = useState(false);

  let listMarital = [
    { label: "Casado / União de Facto", value: "1" },
    { label: "Não Casado", value: "0" },
  ];

  let listTax = [
    { label: "Oficial", value: "1" },
    { label: "Fixa", value: "0" },
  ];

  const { taxData, taxDataDropdown, isLoadingUpdateTaxData } = useSelector(
    (state) => state.personalReducer
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(getTaxDataDropdown());
      dispatch(
        getTaxData(rowCollaborator.funcionarioNum, rowCollaborator.codEntidade)
      );
      setIsNew(false);
      setRowClicked([]);
      setTaxDataPage(true);
    }
  }, [rowCollaborator, isLoadingUpdateTaxData]);

  const handleMaritalStatus = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setMaritalStatusError(false);
    setMaritalSelected(e);
  };

  const handleTax = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setTaxSelectedError(false);
    setTaxSelected(e);
  };
  const handleTaxZone = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setTaxZoneSelectedError(false);
    setTaxZonaSelected(e);
  };

  const handleEffectiveDate = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setEffectiveDateError(false);
    setEffectiveDate(e);
  };

  const handleNumbHolders = (e) => {
    setNumbHolders(e.target.value);
  };

  const handleNumbDepedents = (e) => {
    setNumbDependents(e.target.value);
  };

  const handleNumbDisabledDepedents = (e) => {
    setNumbDisabledDep(e.target.value);
  };

  const handleFlatTaxValue = (e) => {
    setFlatTaxValue(e.target.value);
  };

  const handleDisabledHolderValue = (e) => {
    setDisabledHolderValue(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (
      effectiveDate === null ||
      effectiveDate === undefined ||
      effectiveDate === "" ||
      effectiveDate === 0 ||
      effectiveDate === "0"
    )
      setEffectiveDateError(true);
    if (
      taxZoneSelected === "" ||
      taxZoneSelected === null ||
      taxZoneSelected === undefined ||
      taxZoneSelected === 0 ||
      taxZoneSelected === "0"
    )
      setTaxZoneSelectedError(true);
    if (
      taxSelected === "" ||
      taxSelected === null ||
      taxSelected === undefined ||
      taxSelected === 0 ||
      taxSelected === "0"
    )
      setTaxSelectedError(true);
    if (
      maritalSelected === "" ||
      maritalSelected === null ||
      maritalSelected === undefined ||
      maritalSelected === 0 ||
      maritalSelected === "0"
    )
      setMaritalStatusError(true);
    if (form.checkValidity() === false) {
      setValidated(true);
      setValidatedDropdowns(true);
    } else {
      updateData(false);
      setIsClickedBtnUpdate(!isClickedBtnUpdate);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
  };

  const handleCheckRetiree = (e, condition) => {
    switch (condition) {
      case "awaitingRetirement":
        setCheckAwaitsRetire(e.target.value);
        setCheckRetiree("0");
        break;
      case "retired":
        setCheckRetiree(e.target.value);
        setCheckAwaitsRetire("0");
        break;
      default:
        setCheckRetiree("0");
        setCheckAwaitsRetire("0");
    }
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (taxDataDropdown && Object.keys(taxDataDropdown).length > 0) {
        taxDataDropdown.taxZone = taxDataDropdown.taxZone.filter(zone => zone.label !== "...");        
        setTaxZoneList(taxDataDropdown.taxZone);
      }
      if (data?.length > 0) {
        setNumbHolders(data[0].numberOfHolders);
        setNumbDependents(data[0].numberOfDependentes);
        setNumbDisabledDep(data[0].numberOfDependentesDef);

        // setLepNum(data[0].lepNum);
        setFifEmpCode(data[0].lepNum);
        setFlatTaxValue(data[0].taxaFixaValor);
        setDisabledHolderValue(data[0].percDisable);

        if (data[0].taxOficial === "1") {
          setTaxSelected("1");
        }
        if (data[0].taxFixa === "1") {
          setTaxSelected("0");
        }

        setMaritalSelected(data[0].maritalStatus);

        setCheckRetiree(data[0].retiree);

        setCheckAwaitsRetire(data[0].awaitingRetirement);

        const checkEmptyData = (data, setState) => {
          if (data === null || data === undefined) {
            data = "0";
            setState(data);
          } else {
            setState(data);
          }
        };

        checkEmptyData(data[0].zona, setTaxZonaSelected);

        if (data[0].active == "1") {
          setCheckActive(true);
        } else {
          setCheckActive(false);
        }

        if (data[0].disabledHolder == "1") {
          setCheckDisabledHolder(true);
        } else {
          setCheckDisabledHolder(false);
        }

        if (data[0].disabledSpouse == "1") {
          setCheckdisabledSpouse(true);
        } else {
          setCheckdisabledSpouse(false);
        }

        const checkDate = (date, set) => {
          if (date !== null && date !== undefined) {
            return set(new Date(date));
          } else {
            return set(date);
          }
        };
        checkDate(data[0].effectiveDate, setEffectiveDate);
      } else {
        setNumbHolders("");
        setNumbDependents("");
        setNumbDisabledDep("");
        setTaxZonaSelected("");
        setCheckRetiree("0  ");
        setCheckActive("");
        setCheckAwaitsRetire("0");
        setTaxSelected("");
        setMaritalSelected("");
        setCheckDisabledHolder("");
        setCheckdisabledSpouse("");
        setEffectiveDate("");
      }
    };
    if (rowClicked) {
      dataHandling(rowClicked);
    } else {
      dataHandling(taxData);
    }
  }, [taxData, rowClicked, taxDataDropdown]);

  const formatWithCheckIcon = (cell, row) => {
    if (cell == "1") {
      return <p>&#10003;</p>;
    }
  };

  const formatEndData = (cell) => {
    if (cell !== null) {
      let converToIso = new Date(cell).toISOString();
      return DateTime.fromISO(converToIso).toLocaleString();
    }
  };

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };

  const formatTax = (cell, row) => {
    if (cell !== null) {
      for (let i = 0; i < listTax.length; i++) {
        if (cell == listTax[i].value) {
          return <p>{listTax[i].label}</p>;
        }
      }
    }
  };

  const columns = [
    {
      dataField: "taxOficial",
      text: t(`portalrh.TaxData.Tax`),
      sort: true,
      formatter: formatTax,
      style: { fontSize: "12px" },
    },
    {
      dataField: "effectiveDate",
      text: t(`portalrh.TaxData.EffectiveDate`),
      sort: true,
      formatter: formatEndData,
      style: { fontSize: "12px" },
    },
    {
      dataField: "active",
      text: t(`portalrh.TaxData.Active`),
      formatter: formatWithCheckIcon,
      style: { fontSize: "12px" },
    },
  ];

  const OnTakingBolean = (data) => {
    setEffectiveDateError(false);
    setTaxZoneSelectedError(false);
    setTaxSelectedError(false);
    setMaritalStatusError(false);
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }

  const cleanInputs = () => {
    setNumbHolders("");
    setNumbDependents("");
    setNumbDisabledDep("");
    setTaxZonaSelected("");
    setCheckRetiree("0  ");
    setCheckActive("");
    setCheckAwaitsRetire("0");
    setTaxSelected("");
    setMaritalSelected("");
    setCheckDisabledHolder("");
    setCheckdisabledSpouse("");
    setEffectiveDate("");
  };

  const taxDataObject = {
    id: rowCollaborator.funcionarioNum,
    companyId: rowCollaborator.codEntidade,
    zona: taxZoneSelected,
    taxFixa: taxSelected === "0" ? "1" : "0",
    taxOficial: taxSelected === "1" ? "1" : "0",
    maritalStatus: maritalSelected,
    numberOfHolders: numbHolders,
    numberOfDependentes: numbDependents,
    numberOfDependentesDef: numbDisabledDep,
    taxaFixaValor: flatTaxValue,
    percDisable: disabledHolderValue,

    awaitingRetirement: checkAwaitsRetire,
    retiree: checkRetiree,
    disabledHolder: checkDisabledHolder ? "1" : "",
    disabledSpouse: checkDisabledSpouse ? "1" : "",
    effectiveDate: effectiveDate,
    active: checkActive ? "1" : "",

    utlAlteracao: "Patrtorr",
    dtAlteracao: new Date(),

    lepNum: rowCollaborator.funcionarioNum,
    nifEmpCode: rowCollaborator.funcionarioNum,
  };

  const updateData = (bollean) => {
    setTaxDataPage(bollean);

    let isoDate = new Date(effectiveDate).toISOString();
    isoDate = isoDate.replace("T", " ");
    
    if (isNew) {
      dispatch(createTaxData(taxDataObject));
    } else {
      dispatch(updateTaxData(isoDate, taxDataObject));
    }
  };

  const createNewData = (bollean) => {
    setTaxDataPage(bollean);
    dispatch(createTaxData(taxDataObject));
  };

  const handleUpdateButtonClicked = (bollean) => {
    if (!bollean) {
      setUpdateButtonClicked(true);
    }
    if (bollean) {
      setUpdateButtonClicked(false);
    }
  };

  const handleNewButtonClicked = (bollean) => {
    if (!bollean) {
      cleanInputs();
      setNewButtonClicked(true);
    }
    if (bollean) {
      setNewButtonClicked(false);
    }
  };
  useEffect(() => {
    if (maritalSelected === "0") setCheckdisabledSpouse("");
  }, [maritalSelected]);

  const validateDropdowns = (isSelected, required) => {
    if (validatedDropdowns && required) {
      if (
        isSelected === "" ||
        isSelected === null ||
        isSelected === undefined ||
        isSelected === 0 ||
        isSelected === "0"
      ) {
        return "dropdown-margin dropdown-border-invalid";
      } else {
        return "dropdown-margin dropdown-border-valid";
      }
    }
    if (validatedDropdowns && !required) {
      return "dropdown-margin dropdown-border-valid";
    }
  };

  return (
    <div className="collaboratoRH__view">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="buttons__rhAdmin">
        <ButtonsCreateUpdate
            isClicked={isClicked}
            setIsClicked={setIsClicked}
            setRowClicked={setRowClicked}
            setIsNew={setIsNew}
            cleanInputs={cleanInputs}
          />
          {/* {!updateButtonClicked ? (
            <ButtonsCreate
              createNewData={createNewData}
              OnTakingBolean={OnTakingBolean}
              handleNewButtonClicked={handleNewButtonClicked}
              taxDataPage={taxDataPage}
            />
          ) : null}
          {!newButtonClicked ? (
            <ButtonsUpdate
              OnTakingBolean={OnTakingBolean}
              updateData={updateData}
              taxDataPage={taxDataPage}
              handleUpdateButtonClicked={handleUpdateButtonClicked}
              isClicked={isClickedBtnUpdate}
              setIsClicked={setIsClickedBtnUpdate}
            />
          ) : null} */}
        </div> 
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="3">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.TaxData.TaxZone`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(taxZoneSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={taxZoneList}
                handleSubmit={handleTaxZone}
                defaultValue={taxZoneSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: taxZoneSelectedError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.TaxData.TaxZone`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="3" xl="3">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.TaxData.Tax`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(taxSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={listTax}
                handleSubmit={handleTax}
                defaultValue={taxSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: taxSelectedError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.TaxData.Tax`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          {taxSelected === "0" ? (
            <Col md="2" xl="2">
              <Form.Label
                className="rhcollaborator__labels"
                style={{ height: "1.1rem" }}
              >
                {""}
              </Form.Label>
              <InputGroup>
                <Form.Control
                  id="rhCollaborator_controlGroup"
                  value={flatTaxValue == null ? "" : flatTaxValue}
                  onChange={handleFlatTaxValue}
                  disabled={isClicked ? false : true}
                  style={{}}
                />
                <InputGroup.Text
                  style={{ borderRadius: "0px 23px 23px 0px", height: "35px" }}
                >
                  %
                </InputGroup.Text>
              </InputGroup>
            </Col>
          ) : null}
          <Col md="3" xl="3">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.TaxData.MaritalStatus`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(maritalSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={listMarital}
                handleSubmit={handleMaritalStatus}
                defaultValue={maritalSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: maritalStatusError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.TaxData.MaritalStatus`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col md="4" xl="4">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.TaxData.EffectiveDate`)} <b>*</b>
              </Form.Label>
              <DateTimePicker
                value={effectiveDate}
                onChange={handleEffectiveDate}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className={
                  isClicked && validatedDropdowns
                    ? validateDropdowns(effectiveDate, true)
                    : "form-control"
                }
                disabled={isClicked ? false : true}
                required={true}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: effectiveDateError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.TaxData.EffectiveDate`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          style={{ marginTop: "5px" }}
        >
          <Col md="4" xl="3">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.TaxData.NumberofHolders`)} <b>*</b>
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={numbHolders == null ? "" : numbHolders}
                onChange={handleNumbHolders}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback type="invalid">
                {`O campo ${t(
                  `portalrh.TaxData.NumberofHolders`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4" xl="3">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.TaxData.NumberofDependents`)} <b>*</b>
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={numbDependents == null ? "" : numbDependents}
                onChange={handleNumbDepedents}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback type="invalid">
                {`O campo ${t(
                  `portalrh.TaxData.NumberofDependents`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="4" xl="3">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.TaxData.NumberofDisabledDependents`)} <b>*</b>
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={numbDisabledDep == null ? "" : numbDisabledDep}
                onChange={handleNumbDisabledDepedents}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback type="invalid">
                {`O campo ${t(
                  `portalrh.TaxData.NumberofDisabledDependents`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>

        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="radio"
            name="group1"
            label={t(`portalrh.TaxData.AwaitingRetirement`)}
            checked={checkAwaitsRetire === "1"}
            style={{ paddingBottom: 10 }}
            onChange={(e) => handleCheckRetiree(e, "awaitingRetirement")}
            disabled={isClicked ? false : true}
            value="1"
          />

          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="radio"
            name="group1"
            label={t(`portalrh.TaxData.Retired`)}
            checked={checkRetiree === "1"}
            style={{ paddingBottom: 10 }}
            onChange={(e) => handleCheckRetiree(e, "retired")}
            disabled={isClicked ? false : true}
            value="1"
          />
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="checkbox"
            label={t(`portalrh.TaxData.DisabledHolder`)}
            checked={checkDisabledHolder}
            style={{ paddingBottom: 10 }}
            onChange={() =>
              setCheckDisabledHolder(
                (checkDisabledHolder) => !checkDisabledHolder
              )
            }
            disabled={isClicked ? false : true}
          />
          {checkDisabledHolder ? (
            <Col md="2" xl="2">
              <Form.Label
                className="rhcollaborator__labels"
                style={{ height: "1.1rem" }}
              >
                {""}
              </Form.Label>
              <InputGroup style={{ marginBottom: "50px" }}>
                <Form.Control
                  id="rhCollaborator_controlGroup"
                  value={disabledHolderValue == null ? "" : disabledHolderValue}
                  onChange={handleDisabledHolderValue}
                  disabled={isClicked ? false : true}
                />
                <InputGroup.Text
                  style={{ borderRadius: "0px 23px 23px 0px", height: "35px" }}
                >
                  %
                </InputGroup.Text>
              </InputGroup>
            </Col>
          ) : null}

          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="checkbox"
            label={t(`portalrh.TaxData.DisabledSpouse`)}
            checked={checkDisabledSpouse}
            style={{ paddingBottom: 10 }}
            onChange={() =>
              setCheckdisabledSpouse(
                (checkDisabledSpouse) => !checkDisabledSpouse
              )
            }
            disabled={
              !isClicked ? true : maritalSelected === "0" ? true : false
            }
          />

          <Form.Check
            className="rhcollaborator__labels__"
            inline
            type="checkbox"
            label={t(`portalrh.TaxData.Active`)}
            checked={checkActive}
            style={{ paddingBottom: 10 }}
            onChange={() => setCheckActive((checkActive) => !checkActive)}
            disabled={isClicked ? false : true}
          />
        </div>
        <div>
          <UploadDocument
            isClicked={isClicked}
            uploadFile={taxData}
            rowCollaborator={rowCollaborator}
            id={4}
          />
        </div>
        <RhTableList
          tableId={"37"}
          columns={columns}
          data={taxData}
          onClickedRow={takeClickedRow}
        />
      </Form>
    </div>
  );
};
export default withNamespaces()(RhTaxData);
