import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/pt-br";
import "dayjs/locale/es";
import { ptBR, enUS, esES } from "@mui/x-date-pickers/locales";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { postCreateDocumentExpenses } from "~/store/ducks/openai/actionTypes";
import Alert from "@mui/material/Alert";
import DocumentViewer from "./DocumentViewer";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalExpenses = ({
  openModal,
  setOpenModal,
  handleSubmitIflow,
  documentValues,
  setDocumentValues,
  handleResetState,
  isImage,
  editMode,
  setEditMode,
  imageBase64,
  setHaveCorrection,
  documentName,
  datasetSelected,
  t,
}) => {
  const dispatch = useDispatch();
  const [openDialogSubmit, setopenDialogSubmit] = useState(false);
  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [localeTranslations, setlocaleTranslations] = useState("en");
  const [completeInfo, setCompleteInfo] = useState(false);
  const { user } = useSelector((state) => state.globalReducer);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { documentExpensesResult } = useSelector(
    (state) => state.openaiReducer
  );
  const { openAIConfiguration } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (userSettings && userSettings.language != undefined) {
      if (userSettings?.language?.trim() == "pt")
        setlocaleTranslations("pt-br");
      if (userSettings?.language?.trim() == "es") setlocaleTranslations("es");
      if (userSettings?.language?.trim() == "en") setlocaleTranslations("en");
    }
  }, [userSettings]);

  useEffect(() => {
    if (Object.keys(documentExpensesResult).length > 0) {
      const anyFieldEmpty = Object.values(documentExpensesResult.metadata).some(
        (x) => x === ""
      );

      let metadata = { ...documentExpensesResult.metadata };

      // if (metadata.hasOwnProperty("totalAmount")) {
      //   metadata.totalAmount = metadata.totalAmount.replace(",", ".");
      // }

      setDocumentValues({
        ...metadata,
        base64: imageBase64,
      });

      if (anyFieldEmpty) {
        setCompleteInfo(true);
      }
    }
  }, [documentExpensesResult]);

  const getLocaleText = () => {
    let lang = enUS;
    if (userSettings && userSettings.language != undefined) {
      if (userSettings?.language.trim() === "pt") {
        lang = ptBR;
      }
      if (userSettings?.language.trim() === "es") {
        lang = esES;
      }
    }
    return lang.components.MuiLocalizationProvider.defaultProps.localeText;
  };

  const handleChange = (value, key) => {
    setDocumentValues({
      ...documentValues,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    let payload = {
      flowId: datasetSelected.flowId,
      fileType: documentExpensesResult.fileType,
      metadata: {
        emittingVat: documentValues.emittingVat,
        emittingCompany: documentValues.emittingCompany,
        customerVat: documentValues.customerVat,
        totalAmount: documentValues.totalAmount,
        invoiceDate: documentValues.invoiceDate,
      },
      base64: imageBase64,
      fileName: documentName,
      userId: user.id,
      organizationId: user.organization.id,
    };
    setCompleteInfo(false);
    handleClose();
    dispatch(postCreateDocumentExpenses(payload));
  };
  const handleSubmitConfirmSend = () => {
    setopenDialogSubmit(true);
  };

  const handleClose = () => {
    setopenDialogSubmit(false);
    setOpenDialogCancel(false);
  };

  const handleCancel = (state) => {
    if (state === "filled") {
      if (editMode) {
        let metadata = { ...documentExpensesResult.metadata };

        // if (metadata.hasOwnProperty("totalAmount")) {
        //   metadata.totalAmount = metadata.totalAmount.replace(",", ".");
        // }

        setDocumentValues({
          ...metadata,
          base64: imageBase64,
        });
        setEditMode(!editMode);
      } else {
        setOpenDialogCancel(true);
      }
    }
    if (state === "notFilled") {
      setOpenDialogCancel(true);
    }
    if (state === "close") {
      setOpenDialogCancel(true);
    }
  };

  return (
    <>
      {Object.keys(documentExpensesResult).length > 0 && (
        <Modal
          show={openModal && Object.keys(documentExpensesResult).length > 0}
          dialogClassName={"modal-tarefa-60w custom-modal"}
          centered
          backdrop="static"
          onHide={() => handleCancel("close")}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("openai.expense")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "600px",
                marginBottom: "1rem",
              }}
            >
              <Form>
                {completeInfo && (
                  <Row>
                    <Col>
                      <div style={{ marginBottom: "1rem" }}>
                        <Alert severity="info">
                          {t("openai.completeDataInfo")}
                        </Alert>
                      </div>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col md={6}>
                    {Object.keys(documentValues).map(
                      (key, index) =>
                        key !== "base64" && (
                          <Form.Group className="mb-3" key={index}>
                            <Form.Label>{t(`openai.${key}`)}</Form.Label>
                            {key === "invoiceDate" ? (
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={localeTranslations}
                                localeText={getLocaleText()}
                              >
                                <input
                                  type="hidden"
                                  name="date"
                                  value={dayjs(documentValues[key]).format(
                                    "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                  )}
                                />

                                <DatePicker
                                  sx={{
                                    height: "44px",
                                    borderRadius: "23px",
                                    backgroundColor: editMode
                                      ? "initial"
                                      : "#e9ecef",
                                  }}
                                  className="datePickerExpenses"
                                  value={dayjs(documentValues[key])}
                                  ampm={false}
                                  onChange={(event) =>
                                    handleChange(new Date(dayjs(event)), key)
                                  }
                                  DateTimeFormat={Intl.DateTimeFormat}
                                  disabled={!editMode}
                                />
                              </LocalizationProvider>
                            ) : (
                              <Form.Control
                                autoComplete="off"
                                required
                                type={key === "totalAmount" ? "number" : "text"}
                                value={documentValues[key] || ""}
                                onChange={(event) =>
                                  handleChange(event.target.value, key)
                                }
                                disabled={!editMode}
                                style={
                                  editMode
                                    ? {}
                                    : { color: "rgba(0, 0, 0, 0.38)" }
                                }
                              />
                            )}
                          </Form.Group>
                        )
                    )}
                  </Col>
                  <Col md={6}>
                    {!isMobile && (
                      <DocumentViewer base64={imageBase64} isImage={isImage} />
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              margin="0px"
              justifyContent="flex-end"
            >
              {!completeInfo ? (
                <>
                  <Button
                    className="card-button-red"
                    onClick={() => handleCancel("filled")}
                  >
                    {editMode ? t("openai.back") : t("openai.cancel")}
                  </Button>
                  {!editMode && datasetSelected.correction && (
                    <Button
                      className="card-button-outlined"
                      onClick={() => {
                        setEditMode(!editMode);
                        setHaveCorrection(true);
                      }}
                    >
                      {t("openai.corrigirDados")}
                    </Button>
                  )}

                  <Button
                    className="card-button"
                    onClick={handleSubmitConfirmSend}
                  >
                    {t("openai.submit")}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="card-button-red"
                    onClick={() => handleCancel("notFilled")}
                  >
                    {t("openai.cancel")}
                  </Button>
                  {!editMode && datasetSelected.correction ? (
                    <Button
                      className="card-button-outlined"
                      onClick={() => {
                        setEditMode(!editMode);
                        setHaveCorrection(true);
                      }}
                    >
                      {t("openai.completeData")}
                    </Button>
                  ) : (
                    <Button
                      className="card-button"
                      onClick={() => handleSubmitConfirmSend()}
                    >
                      {t("openai.submit")}
                    </Button>
                  )}
                </>
              )}
            </Stack>
            <Dialog
              open={openDialogSubmit}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <DialogContentText>{t("openai.confirmSend")}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="card-button-red-Dialog"
                  onClick={handleClose}
                >
                  {t("openai.no")}
                </Button>
                <Button className="card-button-Dialog" onClick={handleSubmit}>
                  {t("openai.yes")}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDialogCancel}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <DialogContentText>{t("openai.cancelSend")}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="card-button-red-Dialog"
                  onClick={handleClose}
                >
                  {t("openai.no")}
                </Button>
                <Button
                  className="card-button-Dialog"
                  onClick={() => {
                    handleResetState();
                    handleClose();
                    setCompleteInfo(false);
                  }}
                >
                  {t("openai.yes")}
                </Button>
              </DialogActions>
            </Dialog>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default withNamespaces()(ModalExpenses);
